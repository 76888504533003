import React, {useContext, useEffect, useState} from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {IsotopeFeature} from '../components/isotope-feature';
import Button from '@mui/material/Button';


import { enterAnimation, exitAnimation } from '../components/page-transition';
import { gsap } from "gsap";

import { itemData } from '../data/portfolio-data';
import './portfolio.css';
import { AppContext } from '../state/State';

import { CloseableAlert } from '../components/closeable-alert';



const pageId = "portfolio-content";

const transSpeed = 2;

export const Portfolio = () => {
  const { state } = useContext<any>(AppContext);
  const [ infoModalData, setInfoModalData ] = useState<any>({title: '', content: ''});
  const { prefersReducedMotion } = state;

  const randomAngle = () => {
    const plusOrMinus = Math.random() < 0.5 ? -1 : 1;
    const randomAngleVal = Math.floor(Math.random() * 90 * plusOrMinus);
    return randomAngleVal;
  }
  const closeInfoModal = () => {
  
    document.querySelectorAll('.showing-back').forEach((thisElement: Element) => {
      resetToFront(thisElement);
    });
  
    gsap.fromTo('.info-modal-content', .5, 
      {autoAlpha:1,rotationX:0,scale:1}, 
      {scale:.3,autoAlpha:0, rotationX:-90, rotationY: randomAngle(), transformOrigin:"50% 50% -200", 
        onComplete:function(){
          gsap.to('#info-modal', .5, {autoAlpha: 0, onComplete: () => {
          
            gsap.set('#info-modal', {display: 'none', opacity: 0});
            gsap.set(['html','body'], {overflow: 'hidden auto'});
            setInfoModalData({title: '', content: ''});
          }});
        }
      }
    );
  }
  const openInfoModal = () => {
    gsap.set(['html','body'], {overflow: 'hidden'});
    gsap.set('#info-modal', {display: 'block', opacity: 0});    
    gsap.set('.info-modal-content', {opacity: 0});
    
    //doResizeCheck();
    gsap.to('#info-modal', .5, {autoAlpha: 1, onComplete: () => {
      gsap.to('#loading-box', .5, {autoAlpha: 0, onComplete: () => {
        gsap.set('#loading-box', {display: 'none', opacity: 0});
      }});
      
      gsap.fromTo('.info-modal-content', .5, 
        {autoAlpha:0, rotationY: randomAngle(), rotationX:randomAngle(), scale:.3}, 
        {scale:1, rotationY: 0, autoAlpha:1, rotationX:0, transformOrigin:"50% 50% -200"}
      );
    }});
  }

  // const doResizeCheck = () => {
    
  //   //adjust width
  //   if(window.innerWidth < 980){
      
  //     gsap.set('.info-modal-body',{ height: 'auto'});
  //     gsap.set('.info-modal-content', {
  //       width: window.innerWidth - 25 + 'px',
  //       height: 'auto'
  //     });
  
      
  //     //height
  //     // contentWindow.find('.profile-desc').css({height:'auto'});
  //     // contentWindow.css({height:'auto'});
  //     // if(contentWindow.outerHeight() > $(window).height()){  //if too high
  //     //   contentWindow.css({height: $(window).height() - 25 + 'px'});
  //     // } else { //if fits fine
  //     //   contentWindow.css({height: contentWindow.outerHeight() + 'px'});
  //     // }
  //     gsap.set('.info-modal-content', {
  //       marginLeft: -(window.innerWidth - 25) / 2 + 'px', 
  //       //marginTop: -contentWindow.outerHeight() / 2 + 'px'
  //     });
      
  //   } else {
  //     //width
  //     gsap.set('.info-modal-body',{ height: 'auto'});
  //     gsap.set('.info-modal-content', {
  //       width: '980px',
  //       height: 'auto'
  //     });
      
  //     // //height
  //     // contentWindow.find('.profile-desc').css({height:'auto'});
  //     // contentWindow.css({height:'auto'});
      
  //     // if(contentWindow.outerHeight() > gpModal.maxHeight){  //if too high
  //     //   contentWindow.find('.profile-desc').css({height:'340px'});
  //     //   contentWindow.css({height:gpModal.maxHeight + 'px'});
  //     // } else { //if fits fine
  //     //   contentWindow.css({height: contentWindow.outerHeight() + 'px'});
  //     // }
  //     gsap.set('.info-modal-content', {
  //       marginLeft: -980 / 2 + 'px', 
  //       //marginTop: -contentWindow.outerHeight() / 2 + 'px'
  //     });
  //   }
    
  // }
  
  const loadProfile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    
    //console.log('e', itemData[Number(e.currentTarget.dataset.profileIndex)]);
    setInfoModalData({
      title: itemData[Number(e.currentTarget.dataset.profileIndex)].title,
      content: itemData[Number(e.currentTarget.dataset.profileIndex)].content
    });
    gsap.fromTo('#loading-box', .5, {display: 'block', opacity: .5}, {autoAlpha: 1, onComplete: () => {
      openInfoModal();
    }});
    
  }

  const toggleBack = (e: any) => {
    if(!prefersReducedMotion){
      const whichObj = e.target;
      whichObj.classList.add('showing-back');
      whichObj.classList.remove('showing-front');
      if(whichObj.classList.contains('vertical')){
        gsap.to(whichObj.querySelector('.front'), transSpeed, {rotationY:"0_short", rotationX:"180deg", ease:"back.out"});
        gsap.to(whichObj.querySelector('.right'), transSpeed, {rotationY:"0_short", rotationX:"270deg", ease:"back.out"});
        gsap.to(whichObj.querySelector('.back'), transSpeed, {rotationY:"0_short", rotationX:"0deg", ease:"back.out"});
        gsap.to(whichObj.querySelector('.left'), transSpeed, {rotationY:"0_short", rotationX:"90deg", ease:"back.out"});
      } else {
        gsap.to(whichObj.querySelector('.front'), transSpeed, {rotationX:"0_short", rotationY:"180deg", ease:"back.out"});
        gsap.to(whichObj.querySelector('.right'), transSpeed, {rotationX:"0_short", rotationY:"270deg", ease:"back.out"});
        gsap.to(whichObj.querySelector('.back'), transSpeed, {rotationX:"0_short", rotationY:"0deg", ease:"back.out"});
        gsap.to(whichObj.querySelector('.left'), transSpeed, {rotationX:"0_short", rotationY:"90deg", ease:"back.out"});
      }
    }
  }
  
  const resetToFront = (whichObj: any) => {
    whichObj.classList.add('showing-front');
    whichObj.classList.remove('showing-back');
    if(whichObj.classList.contains('vertical')){
      gsap.to(whichObj.querySelector('.front'), transSpeed, {rotationY:"0_short", rotationX:"0deg", ease:"back.out"});
      gsap.to(whichObj.querySelector('.right'), transSpeed, {rotationY:"0_short", rotationX:"90deg", ease:"back.out"});
      gsap.to(whichObj.querySelector('.back'), transSpeed, {rotationY:"0_short", rotationX:"-180deg", ease:"back.out"});
      gsap.to(whichObj.querySelector('.left'), transSpeed, {rotationY:"0_short", rotationX:"-90deg", ease:"back.out"});
    } else {
      gsap.to(whichObj.querySelector('.front'), transSpeed, {rotationX:"0_short", rotationY:"0deg", ease:"back.out"});
      gsap.to(whichObj.querySelector('.right'), transSpeed, {rotationX:"0_short", rotationY:"90deg", ease:"back.out"});
      gsap.to(whichObj.querySelector('.back'), transSpeed, {rotationX:"0_short", rotationY:"-180deg", ease:"back.out"});
      gsap.to(whichObj.querySelector('.left'), transSpeed, {rotationX:"0_short", rotationY:"-90deg", ease:"back.out"});
    }
  }
  
  const toggleFront = (e: any) => {
    if(!prefersReducedMotion){
      const whichObj = e.target;
      resetToFront(whichObj);
    }
  }
  
  //PageTransition Animation
  useEffect(() => {
    if(!prefersReducedMotion){
      enterAnimation(pageId);
      window.addEventListener('beforeunload', function(){
        console.log('before unload');
        exitAnimation(pageId);
      })
      return () => {
        window.removeEventListener('beforeunload', () => {
          exitAnimation(pageId);
        })
      };
    }
  },[prefersReducedMotion]);

  // const isotopeItems=[
  //   <div className="filter-item vege">
  //     <span>Cucumber</span>
  //   </div>,
  //   <div className="filter-item fruit">
  //     <span>Apple</span>
  //   </div>,
  //   <div className="filter-item fruit">
  //     <span>Orange</span>
  //   </div>,
  //   <div className="filter-item fruit vege">
  //     <span>Tomato</span>
  //   </div>
  // ]

  useEffect(() => {
    gsap.set(".front", {rotationX:"0deg", 
      rotationY:"0deg", 
      rotationZ:"0deg"});
    gsap.set(".right", {rotationX:"0deg", 
      rotationY:"90deg", 
      rotationZ:"0deg"});
    gsap.set(".back", {rotationX:"0deg", 
      rotationY:"-180deg", 
      rotationZ:"0deg"});

    gsap.set(".left", {rotationX:"0deg", 
      rotationY:"-90deg", 
      rotationZ:"0deg"});

  }, []);

  const profileItems = itemData.map((item, itemKey) => (
    <div className={`profile-info filter-item${item.filter.length > 0 ? ' ' + item.filter: ''}`} key={`profile-item-${itemKey}`}>
      <div className="profile-padding">
        <div className="profile-row">
          <div className="cube-container showing-front" onMouseEnter={toggleBack} onMouseLeave={toggleFront}>
            <div className="box">
              <div className="right"></div>
              <div className="back">
                <p className="quote-text">
                  <span>{item.description}</span>
                  <Button className="profile-link" data-profile={item.title} data-profile-index={itemKey} onClick={loadProfile}>View Details »</Button>
                </p>
              </div>
              <div className="left"></div>
              <div className="front">
                { !prefersReducedMotion ? 
                  <img src={item.img} alt={item.title} className="profile-logo-or-photo" width="300" height="300" />
                  :
                  <Button className="profile-link" data-profile={item.title} data-profile-index={itemKey} onClick={loadProfile} sx={{border: 'none', p: 0, m: 0}}>
                    <img src={item.img} alt={item.title} className="profile-logo-or-photo" width="300" height="300" />
                  </Button>
                }
                
              </div>
            </div>
          </div>
          <ul className="profile-info-list">
            <li className="profile-title">
              <Button className="profile-link" data-profile={item.title} data-profile-index={itemKey} onClick={loadProfile}>
                <span>{item.title}</span>
              </Button>
            </li>
            {/* <li className="profile-subtitle">XXXXXXX</li>
            <li className="profile-description">yyyyyy</li> */}
          </ul>
      {/* <ImageListItem key={item.img}>
      <img
        src={`${item.img}?w=248&fit=crop&auto=format`}
        alt={item.title}
        loading="lazy"
      />
    </ImageListItem> */}
        </div>
      </div>
    </div>
  ))

  const InfoModal = () => <div id="info-modal">
  <div className="info-modal-bg" onClick={closeInfoModal}></div>
  <div className="info-modal-content">
    <div className="info-modal-header">{infoModalData.title} <button type="button" className="info-modal-close-btn" title="Close" onClick={closeInfoModal}>&times;</button></div>
    <div className="info-modal-body container-fluid">
    {infoModalData.content ? infoModalData.content : 
    <div>*** PROFILES FOR EACH OF MY PROJECTS ARE COMING SOON!!! ***<br />
    Thanks for your patience!</div>
    }
    
      
    </div>
  </div>
</div>

  return <>
    <div id={pageId} className="page-container">
      <div className="title-bar">
        <Typography variant="h4" component="h1" align="center" color="secondary">
          ThatDevGuy's Portfolio
        </Typography>
      </div>
      <Container maxWidth="xl" component="main" sx={{mb: 10}}>
        <CloseableAlert severity="info">Please note: This section is actively being worked on at this time so some projects may not be fully populated yet or something just doesn't look quite right...</CloseableAlert>
        
        {/* <IsotopeFeature isotopeItems={isotopeItems} /> */}
        <Box>
          {/* TODO: add filter items to component */}
          <IsotopeFeature isotopeItems={profileItems} />
        </Box>
      </Container>
    </div>

    <InfoModal />

    <div id="loading-box">
      <div className="bg-layer"></div>
      <div className="indicator"></div>
    </div>
  </>
}
