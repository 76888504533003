import React from 'react';
import { SwitchTransition, Transition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import { gsap, Back } from "gsap";


type Props = {
  children: JSX.Element;
};

export const enterAnimation = (pageId: string) => {
  gsap.set('#' + pageId, { autoAlpha: 0, scale: 0.9, xPercent: -100 });
  gsap
    .timeline({ paused: true })
    .to('#' + pageId, { autoAlpha: 1, xPercent: 0, duration: .75, ease: Back.easeOut })
    .to('#' + pageId, { scale: 1, duration: 0.5 })
    .play();
}

export const exitAnimation = (pageId: string) => {
  gsap
    .timeline({ paused: true })
    .to('#' + pageId, { scale: 0.9, duration: 0.2 })
    .to('#' + pageId, { xPercent: 100, autoAlpha: 0, duration: 0.2 })
    .play();
}

export const PageTransition: React.FC<Props> = ({ children }) => {
  const location = useLocation();
  return (
    <SwitchTransition>
      <Transition
        key={location.pathname}
        timeout={500}
        onEnter={(node: HTMLElement) => {
          gsap.set(node, { autoAlpha: 0, scale: 0.9, xPercent: -100 });
          gsap
            .timeline({ paused: true })
            .to(node, { autoAlpha: 1, xPercent: 0, duration: .5, ease: Back.easeOut })
            .to(node, { scale: 1, duration: 0.25 })
            .play();
        }}
        onExit={(node) => {
          gsap
            .timeline({ paused: true })
            .to(node, { scale: 0.9, duration: 0.2 })
            .to(node, { xPercent: 100, autoAlpha: 0, duration: 0.2 })
            .play();
        }}
      >
        {children}
      </Transition>
    </SwitchTransition>
  );
};
