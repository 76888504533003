import React, {useContext, useEffect} from 'react';
import { AppContext } from "../state/State";
import { AppLogo } from '../template/app-logo';


import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';


import { enterAnimation, exitAnimation } from '../components/page-transition';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';

const pageId = "home-content";

export const Home = () => {
  const { state } = useContext<any>(AppContext);
  const { prefersReducedMotion } = state;

  //PageTransition Animation
  useEffect(() => {
    if(!prefersReducedMotion){
      enterAnimation(pageId);
      window.addEventListener('beforeunload', function(){
        console.log('before unload');
        exitAnimation(pageId);
      })
      return () => {
        window.removeEventListener('beforeunload', () => {
          exitAnimation(pageId);
        })
      };
    }
  },[prefersReducedMotion]);

  return (
    <div id={pageId} className="page-container">
      <div className="title-bar" style={{background: '#F6FDFF'}}>
        <AppLogo width={300} height={"auto"} withText={false} />
        <Typography variant="h1" sx={{mb: 2, textAlign: 'center'}} color="secondary">
          Welcome to
          <span className="site-name">THAT<span style={{color: '#1b1464'}}>DEV</span>GUY</span>
        </Typography>
      </div>
      <Container maxWidth="xl">
          <Box
          sx={{
            my: 2
          }}
        >
          <Paper sx={{p: 2, mb: 3}}>
            <p>Hi! My name's Christopher.</p>
            <p>Apparently there are quite a bit of "ThatDevGuys" out there... but I bought the domain name not knowing that.  So to get my money's worth, I decided to use it as my portfolio site so you can learn all about my experiences and hopefully hire me.</p>
            <p>My specialty is forging ideas with fellow developers, product designers, or producers and molding it into a finished product that sparkles! (without the mess of glitter)</p>
          </Paper>

          <Alert severity="info" sx={{xs:{p: 4}, sm:{p: 10}, justifyContent: 'center'}}>Please note: This website is actively being worked on at this time.  If you notice something missing or broken, it's cause I'm still trying to find my duct tape.</Alert>
          
          {/* <p>I have dealt with all spectrums of the internetz.  From putting together a comp for a new website design, to </p> */}

          {/* <p>I can go lone-wolf on a project or work with fellow teammates, contractors, or third party agencies.</p> */}
          

        </Box>
      </Container>
    </div>
  );
}
