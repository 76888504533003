import {useContext, useEffect} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { enterAnimation, exitAnimation } from '../components/page-transition';
import { AppContext } from '../state/State';

import Grid from '@mui/material/Grid';
import './privacy-policy.css';

const pageId = 'privacy-policy-content';

export const PrivacyPolicy = () => {
  const { state } = useContext<any>(AppContext);
  const { prefersReducedMotion } = state;  
  //PageTransition Animation
  useEffect(() => {
    if(!prefersReducedMotion){
      enterAnimation(pageId);
      window.addEventListener('beforeunload', function(){
        console.log('before unload');
        exitAnimation(pageId);
      })
      return () => {
        window.removeEventListener('beforeunload', () => {
          exitAnimation(pageId);
        })
      };
    }
  },[prefersReducedMotion]);
  
  return <div id={pageId} className="page-container">
    <div className="title-bar">
      <Typography variant="h4" component="h1" align="center" color="secondary">
        Privacy Policy
      </Typography>
    </div>
    <Container maxWidth="xl">
      <Grid container spacing={4}>
        <Grid item xs={12}>
            <Typography variant="body1" sx={{mb:4}}>
              This Privacy Policy governs the manner in which That Dev Guy LLC collects, uses, maintains and discloses information collected from users (each, a "User") of the http://www.thatdevguy.com website ("Site") and associated products including mobile and web applications. This privacy policy applies to the Site and all products and services offered by That Dev Guy LLC.
            </Typography>
          <div className="privacy-policy-section">


            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              Personal identification information
            </Typography>
            <Typography variant="body1">
              We may collect personal identification information from Users in a variety of ways, including, but not limited to, when Users visit our site, subscribe to the newsletter, and in connection with other activities, services, features or resources we make available on our Site. Users may be asked for, as appropriate, email address. Users may, however, visit our Site anonymously. We will collect personal identification information from Users only if they voluntarily submit such information to us. Users can always refuse to supply personally identification information, except that it may prevent them from engaging in certain Site related activities.
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              Non-personal identification information
            </Typography>
            <Typography variant="body1">
              We may collect non-personal identification information about Users whenever they interact with our Site. Non-personal identification information may include the browser name, the type of computer and technical information about Users means of connection to our Site, such as the operating system and the Internet service providers utilized and other similar information.
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              Web browser cookies
            </Typography>
            <Typography variant="body1">
              Our Site may use "cookies" to enhance User experience. User's web browser places cookies on their hard drive for record-keeping purposes and sometimes to track information about them. User may choose to set their web browser to refuse cookies, or to alert you when cookies are being sent. If they do so, note that some parts of the Site may not function properly.
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              We collect or share your location only with permission
            </Typography>
            <Typography variant="body1">
              In serving you, we may use or store your precise geographic location, if you give us permission to do so. We do not use or share this data for any other purpose. Many devices will indicate through an icon when location services are operating. We only share this location information with others as approved by you.
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              How we use collected information
            </Typography>
            <Typography variant="body1">
              That Dev Guy LLC collects and uses Users personal information for the following purposes:
              <ul>
                <li>To improve customer service - Your information helps us to more effectively respond to your customer service requests and support needs.</li>
                <li>To send periodic emails - The email address Users provide will only be used to respond to their inquiries, and/or other requests or questions. If User decides to opt-in to our mailing list, they will receive emails that may include company news, updates, related product or service information, etc. If at any time the User would like to unsubscribe from receiving future emails, they may do so by contacting us via our Site.</li>
              </ul>
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              Sharing your personal information
            </Typography>
            <Typography variant="body1">
              We do not sell, trade, or rent Users personal identification information to others. We may share generic aggregated demographic information not linked to any personal identification information regarding visitors and users with our business partners, trusted affiliates and advertisers for the purposes outlined above.
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              Ad companies collect anonymous data. You can opt out.
            </Typography>
            <Typography variant="body1">
              Ad companies may use and collect anonymous data about your interests to customize content and advertising here and in other sites and applications. Interest and location data may be linked to your device, but is not linked to your identity. Click to see company privacy policies and opt-out choices:
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              AdMob (Google)
            </Typography>
            <Typography variant="body1">
              AdMob knows performance mobile advertising. As one of the largest performance mobile ad networks worldwide, AdMob offers performance advertisers sophisticated targeting capabilities and low-cost customer acquisition with measurable return on investment.
            </Typography>
            <Typography variant="body1">
              <a href="http://www.google.com/analytics/learn/privacy.html" target="_blank">Privacy Policy and Choices</a>
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              Service providers access data on our behalf
            </Typography>
            <Typography variant="body1">
              In order to serve you, we may share your personal and anonymous information with other companies, including vendors and contractors. Their use of information is limited to these purposes, and subject to agreements that require them to keep the information confidential. Our vendors provide assurance that they take reasonable steps to safeguard the data they hold on our behalf, although data security cannot be guaranteed.
            </Typography>
            <Typography variant="body1">
              Analytics companies may access anonymous data (such as your IP address or device ID) to help us understand how our services are used. They use this data solely on our behalf. They do not share it except in aggregate form; no data is shared as to any individual user. Click to see company privacy policies that govern their use of data.
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              Google Analytics
            </Typography>
            <Typography variant="body1">
              Google Analytics is a web analytics tool that helps website owners understand how visitors engage with their website. Google Analytics customers can view a variety of reports about how visitors interact with their website so they can improve it. Google Analytics collects information anonymously. It reports website trends without identifying individual visitors.
            </Typography>
            <Typography variant="body1">
              <a href="https://support.google.com/admob/answer/6128543?hl=en&ref_topic=2745287" target="_blank">Privacy Policy and Choices</a>
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              We take steps to protect personal information
            </Typography>
            <Typography variant="body1">
              We take reasonable steps to secure your personally identifiable information against unauthorized access or disclosure. We encrypt transmission of data on pages where you provide payment information. However, no security or encryption method can be guaranteed to protect information from hackers or human error.
            </Typography>
            <Typography variant="body1">
              Information we collect may be stored or processed on computers located in any country where we do business.
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              Special situations may require disclosure of your data
            </Typography>
            <Typography variant="body1">
              To operate the service, we also may make identifiable and anonymous information available to third parties in these limited circumstances: (1) with your express consent, (2) when we have a good faith belief it is required by law, (3) when we have a good faith belief it is necessary to protect our rights or property, or (4) to any successor or purchaser in a merger, acquisition, liquidation, dissolution or sale of assets. Your consent will not be required for disclosure in these cases, but we will attempt to notify you, to the extent permitted by law to do so.
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              You can review more privacy-related information
            </Typography>
            <Typography variant="body1">
              This privacy policy was last updated on May 31, 2023. Our privacy policy may change from time to time. If we make any material changes to our policies, we will place a prominent notice on our website or application. If the change materially affects registered users, we will send a notice to you by email, push notification or text.
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              Changes to this privacy policy
            </Typography>
            <Typography variant="body1">
              That Dev Guy LLC has the discretion to update this privacy policy at any time. When we do, we will revise the updated date at the bottom of this page. We encourage Users to frequently check this page for any changes to stay informed about how we are helping to protect the personal information we collect. You acknowledge and agree that it is your responsibility to review this privacy policy periodically and become aware of modifications.
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              Your acceptance of these terms
            </Typography>

            <Typography variant="body1">
              By using this Site, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our Site. Your continued use of the Site following the posting of changes to this policy will be deemed your acceptance of those changes.
            </Typography>

            <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
              Contacting us
            </Typography>
            <Typography variant="body1">
              If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us at:<br />
              {/* Phone: +1 ###-###-####<br /> */}
              Email: chris@thatdevguy.com<br />
            </Typography>
          </div>

        </Grid>
      </Grid>
    </Container>
  </div>
}