import React, {useContext, useEffect} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { enterAnimation, exitAnimation } from '../components/page-transition';
import { AppContext } from '../state/State';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import TopBorderImg from '../static/images/content-header-line.png';
import ThatDevGuyPortrait from '../static/images/thatDevGuy-portrait.jpg';
import './about.css';

const pageId = 'about-content';

export const About = () => {
  const { state } = useContext<any>(AppContext);
  const { prefersReducedMotion } = state;  
  //PageTransition Animation
  useEffect(() => {
    if(!prefersReducedMotion){
      enterAnimation(pageId);
      window.addEventListener('beforeunload', function(){
        console.log('before unload');
        exitAnimation(pageId);
      })
      return () => {
        window.removeEventListener('beforeunload', () => {
          exitAnimation(pageId);
        })
      };
    }
  },[prefersReducedMotion]);
  
  return <div id={pageId} className="page-container">
    <div className="title-bar">
      <Typography variant="h4" component="h1" align="center" color="secondary">
        About ThatDevGuy
      </Typography>
    </div>
    <Container maxWidth="xl" sx={{mb: 4}}>
      <Alert severity="info" sx={{xs: {p:4}, sm: {p:10}, justifyContent: 'center'}}>Please note: This section is actively being worked on at this time.  Unfortunately, I'm not very good at talking about myself, so this area just might take a bit little longer to complete.</Alert>
    </Container>
    <Container maxWidth="xl">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>

<div className="about-section">
  <img className="about-top-border" src={TopBorderImg} alt="" />
  <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
    Characteristics
  </Typography>
  <img src={ThatDevGuyPortrait} className="parallax" alt="" />
  <Typography variant="body1">
    This might sound weird, but this part of my portfolio is the hardest because I don’t usually talk about myself.  On top of that, I am in no way a salesman.  Ironically, in order to get the next big gig, I am aware that I have to sell myself.  So, here we go!  Be sure to swipe right!
  </Typography>
  <Typography variant="body1">
    I consider myself a humble person and a big believer of not patting myself on the back.  To me, it speaks volumes if others express on my behalf the wonderful work I’ve done.  It validates the hard work and thought I put into each project.
  </Typography>
  <Typography variant="body1">
  I have a “leave no man behind” mentality and care greatly for each member of my team.  I also make every effort to ensure a project meets its deadline.
  </Typography>
  <Typography variant="body1">
  In general, I am chill, mellow, and an all around nice guy.  Sometimes a little too nice.  I was brought up by the Golden Rule and live by it everyday.  Oh, and I love coffee...  MUST DRINK COFFEE.
  </Typography>
</div>


<div className="about-section">
  <img className="about-top-border" src={TopBorderImg} alt="" />
  <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
  Product, Website, & UI/UX Design
  </Typography>
  <Typography variant="body1">
  I have years of experience designing websites and mocking up wireframes and prototypes.  Earlier in my career, it was creating artboards with Illustrator or Photoshop.  Now with Adobe XD or Figma, I’m able to share the creative process with the teams to finalize the designs.
  </Typography>
  <Typography variant="body1">
  One major advantage of having somebody who codes do the actual UI/UX design is that they know what is possible on the frontend of the website instead of spending hours of prototyping something and finding out it’s not possible (which saves a considerable amount time).  On top of that, I consider myself very detailed and will stay consistent with the little things like margins, padding and alignment of things.
  </Typography>
  <Typography variant="body1">
  While designing, I also envision myself as the actual customer.  How would I use this product and what should major call to actions be?  The end to end user-journey continuously flows through my mind while designing and sometimes even while I’m dreaming in bed.
  </Typography>
</div>

        </Grid>
        <Grid item xs={12} sm={6}>

<div className="about-section">
  <img className="about-top-border" src={TopBorderImg} alt="" />
  <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
  My Coding Experience
  </Typography>
  <Typography variant="body1">
  I have more than a decade of coding experience and have been exposed to many different languages throughout my career.  In my most recent position, I have been coding in React/Typescript, JSON and Node, creating, maintaining and enhancing components for Gap Inc. and contributing to their headless CMS initiatives.  I have also created Storybook stories and documentation as well as unit tests using React Testing Library or Jest.
  </Typography>
  <Typography variant="body1">
  Aside from that, I have years of experience in creating all kinds of proprietary CMS systems, components, widgets, complete websites and marketing campaigns in other languages as well.  I am confident in being able to pick up a new programming language pretty easily if the need arises.  
  </Typography>
  <Typography variant="body1">
  I excel in creating beautiful and responsive websites using good ol HTML (JSX), Javascript and SASS/CSS (or CSS in JS).  I can take a design from the creative team or UX team and make it pixel perfect along with integrating the desired animations, interactions and behaviors.
  </Typography>
  <Typography variant="body1">
  I believe in the KISS Principle. (keep it simple, stupid).  I have seen way too many instances of companies over engineering something and having it end up being time consuming and expensive to maintain.  I code with minimal libraries so the codebase is slim and little to no dependencies.  When a situation like this arises, I call it out and work with the team to find compromises.
  </Typography>
</div>

<div className="about-section">
  <img className="about-top-border" src={TopBorderImg} alt="" />
  <Typography className="about-section-title" variant="h4" component="h2" color="secondary">
  A Typical Day at Work
  </Typography>
  <Typography variant="body1">
  In my day to day, I had worked closely with project managers, brand producers, product designers, and stakeholders to review or conceptualize a campaign or a new functionality.  After that, I either paired with a fellow developer or went lone-wolf to execute the plan and bring the idea to life.  I would also assist in mentoring other developers and get down to doing some code reviews.
  </Typography>
  <Typography variant="body1">
  I am also known to think outside the box (or inside with a twist) and create simple tools that maximized efficiency within the team or org.
  </Typography>
  <Typography variant="body1">
  One example is at Gap Inc. I developed a Chrome Extension that provided other web developers a mini-hub for tools (kind of like a Swiss Army knife for your browser) where it identified missing alt tags or made links immediately visible for making QA’ing a website faster.
  </Typography>
  <Typography variant="body1">
  Another example is while I was enhancing the content management system for E3 Expo, I created a drag and drop module for the authors and editors to easily bulk upload and process videogame screenshots and videos.  This increased their ability to push content tenfold.
  </Typography>
</div>

        </Grid>
      </Grid>
    </Container>
  </div>
}