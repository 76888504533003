import React, {useEffect} from 'react';
import { gsap, Back } from "gsap";

type AppLogoProps = {
    width?: number | string;
    height?: number | string;
    withText?: boolean;
}

export const AppLogo = (props: AppLogoProps) => {
    const {width=200, height=110.59, withText=true} = props;
    const blinkTimeline = gsap.timeline({
        paused: true,
        repeat: 0,
        yoyo: false,
        //defaults: { duration: 1, ease: "easeInOut" },
        onCompleteParams: ["{self}"],
        onComplete: function() {
            //delay, callback, params, scope
            gsap.delayedCall((6 * Math.random() + 5), function(){blinkTimeline.restart()}, []);
        }
    });

    const rightArmTimeline = gsap.timeline({
        paused: true,
        repeat: 0,
        yoyo: false,
        //defaults: { duration: 1, ease: "easeInOut" },
        onCompleteParams: ["{self}"],
        onComplete: function() {
            //delay, callback, params, scope
            gsap.delayedCall((3 * Math.random() + 5), function(){rightArmTimeline.restart()}, []);
        }
    });

    const leftArmTimeline = gsap.timeline({
        paused: true,
        repeat: 0,
        yoyo: false,
        //defaults: { duration: 1, ease: "easeInOut" },
        onCompleteParams: ["{self}"],
        onComplete: function() {
            //delay, callback, params, scope
            gsap.delayedCall((10 * Math.random() + 2.5), function(){leftArmTimeline.restart()}, []);
        }
    });

    const creeperAnimation = gsap.timeline({
        paused: true,
        repeat: 0,
        yoyo: false,
    });

    useEffect(() => {
        const blinkEyes = gsap.fromTo('#left-eye, #right-eye', {scaleY: 0, transformOrigin: "center bottom"}, {scaleY: 1, transformOrigin: "center bottom", duration: .2});
        blinkTimeline.add([blinkEyes]);
        //blinkTimeline.progress(1).progress(0);
        blinkTimeline.play();

        const rightArm = gsap.fromTo('#right-arm', {x: -2, y: 0, transformOrigin: "right bottom"}, {x: 3, y: 1, transformOrigin: "right bottom", duration: .75});
        rightArmTimeline.add([rightArm]);
        rightArmTimeline.play();

        const leftArm = gsap.fromTo('#left-arm', {x: 0, y: -1, transformOrigin: "center bottom"}, {x: 1, y: 1, transformOrigin: "left bottom", duration: .25});
        leftArmTimeline.add([leftArm]);
        leftArmTimeline.play();

        creeperAnimation.add(gsap.to('#Creeper', { y: -90, ease: Back.easeOut }));
        
        
    }, []);

    const creeperAnimateUp = () => {
        creeperAnimation.play();
    }
    const creeperAnimateDown = () => {
        creeperAnimation.totalTime(creeperAnimation.rawTime() % 1.5).reverse();
    }

    const logoWithText = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="658.6px"
    height="364.167px" viewBox="-293.333 -195.333 658.6 364.167" enableBackground="new -293.333 -195.333 658.6 364.167"
    xmlSpace="preserve" style={{width: width, height: height, margin: '0 auto', display: 'block'}} onMouseEnter={creeperAnimateUp} onMouseLeave={creeperAnimateDown}>
<g id="Layer_14">
   <g>
       <g>
           <path fill="#1B1464" d="M122.663-95.56h32.592c10.604,0,18.558,2.846,23.862,8.536c5.302,5.693,7.954,14.035,7.954,25.026V6.678
               c0,10.994-2.652,19.336-7.954,25.026c-5.305,5.693-13.259,8.536-23.862,8.536h-32.592V-95.56z M154.867,20.84
               c3.492,0,6.175-1.034,8.051-3.104c1.873-2.067,2.813-5.432,2.813-10.088v-70.616c0-4.656-0.94-8.018-2.813-10.088
               c-1.876-2.067-4.559-3.104-8.051-3.104h-10.864v97H154.867z"/>
           <path fill="#1B1464" d="M201.619-95.56h58.2v19.4h-36.86v35.89h29.294v19.4h-29.294v41.71h36.86v19.4h-58.2V-95.56z"/>
           <path fill="#1B1464" d="M266.606-95.56h21.534L302.108,9.782h0.388L316.464-95.56h19.595l-20.564,135.8H287.17L266.606-95.56z"/>
       </g>
       <g>
           <path fill="#3FA9F5" d="M136.485-174.223h-13.8v-12h40.8v12h-13.8v72h-13.2V-174.223z"/>
           <path fill="#3FA9F5" d="M181.845-186.223h13.2v34.2h14.16v-34.2h13.2v84h-13.2v-37.8h-14.16v37.8h-13.2V-186.223z"/>
           <path fill="#3FA9F5" d="M254.444-186.223h17.88l13.68,84h-13.199l-2.4-16.68v0.24h-15l-2.4,16.44h-12.239L254.444-186.223z
                M268.844-130.063l-5.88-41.52h-0.239l-5.761,41.52H268.844z"/>
           <path fill="#3FA9F5" d="M309.164-174.223h-13.8v-12h40.8v12h-13.8v72h-13.2V-174.223z"/>
       </g>
       <g>
           <path fill="#3FA9F5" d="M129.401,154.779c-4.48-4.854-6.72-11.813-6.72-20.881V75.02c0-9.065,2.239-16.025,6.72-20.881
               c4.48-4.852,10.985-7.28,19.52-7.28c8.533,0,15.04,2.428,19.521,7.28c4.479,4.855,6.72,11.815,6.72,20.881v9.6h-16.64V73.898
               c0-7.359-3.04-11.039-9.12-11.039s-9.12,3.68-9.12,11.039v61.281c0,7.254,3.04,10.879,9.12,10.879s9.12-3.625,9.12-10.879
               v-21.921h-8.8v-16h25.439v36.64c0,9.068-2.24,16.027-6.72,20.881c-4.48,4.854-10.987,7.279-19.521,7.279
               C140.387,162.059,133.882,159.634,129.401,154.779z"/>
           <path fill="#3FA9F5" d="M207.8,154.779c-4.479-4.854-6.72-11.813-6.72-20.881V48.459h17.6v86.721c0,3.84,0.773,6.614,2.32,8.319
               c1.545,1.708,3.76,2.56,6.641,2.56c2.88,0,5.092-0.852,6.64-2.56c1.545-1.705,2.32-4.479,2.32-8.319V48.459h16.96v85.439
               c0,9.068-2.24,16.027-6.721,20.881c-4.479,4.854-10.987,7.279-19.52,7.279C218.785,162.059,212.28,159.634,207.8,154.779z"/>
           <path fill="#3FA9F5" d="M297.398,112.779l-21.279-64.32h18.72l12,41.12h0.32l12-41.12h17.119l-21.279,64.32v47.68h-17.601
               V112.779z"/>
       </g>
   </g>
</g>
<g id="blue-bg">
   <path fill="#29ABE2" d="M90.918-15.05c0,95.695-77.318,173.273-172.697,173.273c-95.379,0-172.699-77.578-172.699-173.273
       c0-95.696,77.32-173.273,172.699-173.273C13.6-188.324,90.918-110.747,90.918-15.05z"/>
</g>
<g id="Creeper">
   <g>
       <polygon fill="#39A83F" points="-96.413,116.762 -174.537,128.347 -174.784,-0.955 -98.877,-7.855 		"/>
       <polygon fill="#39A83F" points="-203.25,107.701 -174.537,128.404 -174.599,10.561 -203.341,9.782 		"/>
       <polygon fill="#3D663A" points="-203.341,9.782 -125.494,1.403 -98.877,6.702 -174.599,13.758 		"/>
       <rect x="-203.25" y="23.707" fill="#36812F" width="29.205" height="14.787"/>
       <rect x="-182.548" y="37.306" fill="#36812F" width="8.503" height="14.787"/>
       <rect x="-203.25" y="35.209" fill="#36812F" width="7.394" height="14.787"/>
       <polygon fill="#36812F" points="-164.803,50.816 -174.045,52.093 -174.045,23.707 -164.803,22.906 		"/>
       <polygon fill="#286426" points="-117.238,96.855 -126.48,98.563 -126.48,60.547 -117.238,59.473 		"/>
       <polygon fill="#286426" points="-126.356,85.576 -135.599,86.732 -135.599,60.974 -126.356,60.246 		"/>
       <polygon fill="#286426" points="-153.897,63.064 -164.803,63.688 -164.803,49.785 -153.897,49.393 		"/>
       <polygon fill="#286426" points="-144.656,75.126 -153.897,76.346 -153.897,49.176 -144.656,48.408 		"/>
       <polygon fill="#56D256" points="-173.398,126.034 -174.908,125.435 -174.908,15.101 -173.182,15.447 		"/>
       <polygon fill="#286426" points="-144.193,101.883 -164.341,102.508 -164.341,88.604 -144.193,88.212 		"/>
       <polygon fill="#286426" points="-144.656,123.201 -153.897,125.185 -153.897,115.404 -144.656,115.128 		"/>
       <polygon fill="#286426" points="-153.897,125.283 -163.139,126.034 -163.139,109.319 -153.897,108.85 		"/>
       <polygon fill="#286426" points="-118.347,33.326 -109.104,32.703 -109.104,46.607 -118.347,47 		"/>
       <polygon fill="#286426" points="-136.522,11.028 -127.28,9.321 -127.28,47.338 -136.522,48.411 		"/>
       <polygon fill="#286426" points="-127.404,22.307 -118.162,21.15 -118.162,46.909 -127.404,47.637 		"/>
       <polygon fill="#286426" points="-117.238,32.756 -99.863,31.537 -99.863,58.709 -117.238,59.476 		"/>
       <polygon fill="#286426" points="-109.104,19.451 -99.863,18.295 -99.863,44.053 -109.104,44.78 		"/>
       <polygon fill="#286426" points="-97.2,116.594 -106.441,117.751 -106.441,91.992 -97.2,91.264 		"/>
       <rect x="-182.548" y="91.168" fill="#36812F" width="7.64" height="25.822"/>
       <rect x="-189.756" y="77.617" fill="#36812F" width="7.394" height="14.12"/>
       <polygon fill="#39A83F" points="-91.237,9.413 -185.381,10.891 -185.381,-91.632 -90.744,-91.632 		"/>
       <polygon fill="#39A83F" points="-185.381,10.891 -210.766,2.02 -210.273,-91.632 -185.381,-91.632 		"/>
       <polygon fill="#56D256" points="-184.858,8.904 -186.367,8.425 -186.367,-89.691 -184.643,-89.414 		"/>
       <polyline fill="#191F1D" points="-113.972,-27.462 -113.972,-3.063 -161.661,-3.063 -161.661,-27.462 		"/>
       <rect x="-174.045" y="-64.893" fill="#243E23" width="24.769" height="24.77"/>
       <rect x="-162.954" y="-53.433" fill="#2A2623" width="13.308" height="13.31"/>
       <rect x="-126.356" y="-64.985" fill="#243E23" width="24.769" height="24.768"/>
       <rect x="-126.356" y="-53.524" fill="#2A2623" width="13.309" height="13.307"/>
       <rect x="-161.661" y="-27.555" fill="#2A4029" width="12.384" height="12.384"/>
       <rect x="-126.356" y="-27.647" fill="#2A4029" width="12.384" height="12.385"/>
       <rect x="-149.646" y="-39.754" fill="#2A4029" width="23.29" height="12.385"/>
       <rect x="-161.661" y="-2.972" fill="#2A4029" width="12.384" height="12.385"/>
       <rect x="-126.356" y="-3.063" fill="#2A4029" width="12.384" height="12.385"/>
       <rect x="-139.542" y="-77.954" fill="#337D2E" width="13.062" height="13.062"/>
       <rect x="-186.737" y="-77.954" fill="#337D2E" width="13.061" height="13.062"/>
       <rect x="-172.566" y="-91.016" fill="#337D2E" width="13.061" height="13.062"/>
       <rect x="-113.972" y="-40.094" fill="#337D2E" width="13.062" height="13.063"/>
       <rect x="-113.972" y="-3.742" fill="#337D2E" width="13.062" height="13.063"/>
       <rect x="-104.668" y="-91.016" fill="#337D2E" width="13.061" height="13.062"/>
       <polygon fill="#337D2E" points="-195.856,-6.113 -199.922,-7.592 -199.922,-44.932 -195.856,-44.684 		"/>
       <polygon fill="#337D2E" points="-199.922,-18.867 -201.894,-19.915 -201.894,-32.238 -199.922,-31.93 		"/>
       <polygon fill="#337D2E" points="-202.017,-55.403 -207.192,-56.636 -207.192,-69.39 -202.017,-68.466 		"/>
       <polygon fill="#337D2E" points="-187.107,-4.696 -189.571,-5.744 -189.571,-18.066 -187.107,-17.758 		"/>
       <polygon fill="#337D2E" points="-196.164,-67.542 -198.628,-68.588 -198.628,-80.913 -196.164,-80.603 		"/>
       <polygon fill="#337D2E" points="-190.188,-67.542 -192.652,-68.588 -192.652,-80.913 -190.188,-80.603 		"/>
       <polygon fill="#337D2E" points="-206.7,3.713 -209.165,2.667 -209.165,-9.657 -206.7,-9.348 		"/>
   </g>
   <g id="Layer_17" opacity="0.8">
   </g>
</g>
<g id="body">
   <g>
       <path fill="#5C9732" d="M42.185,76.434l-118.548-9.84c0,0-3.367-87.077,32.197-85.167c35.565,1.908,43.905,13.655,86.351-1.909
           c17.563-3.965,26.782,9.251,28.833,16.59C73.064,3.455,42.185,76.434,42.185,76.434z"/>
       <path fill="#7AC943" d="M23.158,60.279l-112.986-6.314c0,0,2.342-80.617,19.026-86.342c33.808-11.454,51.518,11.454,93.96-4.258
           C40.721-40.6,62.673-27.972,64.576-20.63S23.158,60.279,23.158,60.279z"/>
       <path fill="#6E7280" d="M-11.528-17.253l-17.855,70.927l1.757-70.487L-11.528-17.253z"/>
       <path fill="#DCE4FF" d="M-10.064-17.253L-27.92,53.674l1.755-70.487L-10.064-17.253z"/>
   </g>
</g>
<g id="desk">
   
       <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-100.9028" y1="-40.4302" x2="-82.3258" y2="49.7234" gradientTransform="matrix(1 0 0 -1.0033 0 96.692)">
        <stop  offset="0" style={{stopColor: '#DDC4A7'}}/>
        <stop  offset="1" style={{stopColor: '#C69C6D'}}/>
   </linearGradient>
   <path fill="url(#SVGID_1_)" d="M75.845,55.877l-318.468-7.787c25.172,64.469,87.667,110.133,160.844,110.133
       C-11.675,158.223,48.77,116.227,75.845,55.877z"/>
</g>
<g id="head">
   <g>
       <path fill="#FFDCA9" d="M-34.214-132.524L-55.5-111.5c0,0,8.137,62.033,10.333,84.5C-40-19.833-30.994-12.701-10.651-12.701
           c20.345,0,50.934-12.48,51.663-14.685c0.732-2.202,24.588-65.344,20.93-74.155C58.283-110.349-34.214-132.524-34.214-132.524z"/>
       <path fill="#FFDCA9" d="M61.198-69.185c-0.458,4.926-3.179,8.699-6.076,8.427c-2.896-0.271-4.875-4.483-4.417-9.409
           s3.178-8.699,6.074-8.427C59.677-78.324,61.655-74.111,61.198-69.185z"/>
       <path fill="#FFEFBF" d="M-35.678-135.459c-12.322,1.626-19.155,15.793-22.155,21.293s4.081,34.421,3.854,38.831
           C-55.667-42.5-46.833-44.667-47.167-27.5c0.732,7.193,14.708,11.862,35.053,11.862c20.343,0,50.931-12.481,51.663-14.683
           c0.732-2.204,24.588-65.346,20.93-74.155C56.818-113.288,51.5-148.833-35.678-135.459z"/>
       <path fill="#FFEFBF" d="M60.423-70.393c-0.456,4.926-3.176,8.699-6.074,8.429c-2.896-0.271-4.874-4.485-4.417-9.411
           c0.458-4.924,3.179-8.697,6.074-8.427C58.904-79.532,60.881-75.319,60.423-70.393z"/>
       <path fill="#42210B" d="M-24.993-159.69l-15.221-4.403l-30.588,7.341l20.488,10.279l-36.589,4.406l24.882,12.481l-33.662,13.948
           l24.148,4.406l9.514,4.847l8.771-4.366l4.25,1.875l8.625-5.375l4.1,2l8.355-6.75l3.513,3l8.488-3.308l3.323,4.933L-0.625-121
           l4.125,7.5l9-8.333L20.333-113l3.5-5L29-109l4.167-5.5l6.5,11.833l6.458-3.208L48.5-93.529l3.75,1.555L53.875-87
           c0,0-1.153,12.763,0.458,10.333c2.688-4.05,4.846-0.742,4.846-0.742l5.542-30.885l15.368-25.698c0,0-19.759-1.468-24.149-1.468
           c-4.39,0-18.294-22.761-18.294-22.761l-24.881,2.202l3.66-11.747L-24.993-159.69z"/>
       <path fill="#42210B" d="M15.107-48.677c0.147,0.735-11.562,5.728-23.855,7.93c-10.245,1.908-25.904,2.643-26.05,1.908
           c-0.147-0.734,16.098-3.817,26.343-5.726C1.79-46.474,14.962-49.41,15.107-48.677z"/>
       <path fill="#EDC085" d="M-14.017-65.565c0,0.296-2.487,1.322-5.122,1.322c-2.049,0-5.269-0.881-5.269-1.175
           c0-0.293,3.365-0.293,5.414-0.293C-16.797-65.71-14.017-65.858-14.017-65.565z"/>
       <path fill="#42210B" d="M-33.336-67.913c0,0.242-0.196,0.439-0.438,0.439s-0.439-0.197-0.439-0.439c0-0.243,0.197-0.44,0.439-0.44
           S-33.336-68.156-33.336-67.913z"/>
   </g>
</g>
<g id="left-eye">
   <path fill="#42210B" d="M10.717-79.662c1.464-5.726-0.584-11.158-4.391-12.186c-3.804-0.882-8.049,2.936-9.513,8.664
       c-1.463,5.726-3.952,9.543,4.391,12.186C10.717-68.06,9.4-73.933,10.717-79.662z"/>
</g>
<g id="left-brow">
   <path fill="#42210B" d="M15.84-90.234c-1.61-5.726-6.585-11.6-12.879-7.342c-4.829,3.377,3.072,0.294,7.172,3.377
       C14.084-91.409,17.449-84.505,15.84-90.234z"/>
</g>
<g id="right-eye">
   <path fill="#42210B" d="M-30.262-86.269c-1.61-5.726-6.001-9.397-9.807-8.368c-3.804,1.026-5.562,6.607-3.804,12.333
       c1.609,5.729,1.316,10.279,9.804,8.371C-24.555-76.135-28.652-80.541-30.262-86.269z"/>
</g>
<g id="right-brow">
   <path fill="#42210B" d="M-49.288-93.169c1.609-5.729,6.587-11.602,12.878-7.342c4.832,3.377-3.071,0.293-7.171,3.377
       C-47.533-94.344-50.898-87.444-49.288-93.169z"/>
</g>
<g id="right-arm">
   <g>
       <path fill="#53864B" d="M-199.788,62.777c-15.221,0.438-20.197-14.539-20.197-14.539s26.197-6.458,47.713-25.549
           c16.099-14.392,86.746-51.517,92.015-53.425c12.001-4.406-3.451,38.402-15.452,49.563
           C-115.029,36.597-187.347,62.334-199.788,62.777z"/>
       <path fill="#7AC943" d="M-195.397,56.9c-15.221,0.443-20.197-14.535-20.197-14.535s26.197-6.462,47.713-25.553
           C-151.783,2.425-86.11-29.41-80.841-31.319c12.001-4.405,4.712,31.118-7.289,42.274C-107.449,28.725-182.956,56.463-195.397,56.9z
           "/>
       <path fill="#FFDCA9" d="M-186.323,52.789c0,5.6-13.105,10.131-29.271,10.131c-16.167,0-29.27-4.531-29.27-10.131
           c0-5.592,13.104-10.132,29.27-10.132C-199.429,42.657-186.323,47.197-186.323,52.789z"/>
       <path fill="#FFEFBF" d="M-240.035,50.148c-0.878-2.645-0.586-4.847,4.684-6.757c5.121-1.906,9.659-0.878,11.123,1.324
           c7.462,11.453-2.489,6.167-7.758,8.074C-237.254,54.699-239.012,52.789-240.035,50.148z"/>
       <path fill="#FFEFBF" d="M-228.912,48.238c-1.171-3.228-0.732-6.167,5.854-8.517c6.585-2.35,12.146-1.029,13.904,1.615
           c9.511,14.388-3.22,7.783-9.661,10.132C-225.399,53.965-227.741,51.469-228.912,48.238z"/>
       <g>
           <path fill="#191919" d="M-189.25,63.807c-3.66,0.291-62.64-0.443-66.885-0.734c-2.194-0.152,13.611-10.133,19.172-11.457
               c18.441-4.55,37.906-1.172,40.395-0.732C-194.227,51.029-185.592,63.512-189.25,63.807z"/>
           <path fill="#40484B" d="M-189.25,63.807c-3.66,0.291-15.807-10.133-20.051-10.428c-2.194-0.146-33.223-0.295-27.662-1.764
               c18.441-4.55,37.906-1.172,40.395-0.732C-194.227,51.029-185.592,63.512-189.25,63.807z"/>
       </g>
       <path fill="#FFEFBF" d="M-205.35,63.363c1.316,2.201,9.952,2.496,18.733-0.291c8.928-2.793,9.074-6.318,7.757-8.373
           c-9.367-15.417-9.513-1.76-18.441,1.025C-205.934,58.664-206.667,61.162-205.35,63.363z"/>
       <path fill="#FFEFBF" d="M-207.837,41.189c0.584-1.906,6.293-1.763,12.733,0.439c6.439,2.058,11.123,5.437,10.536,7.346
           c-0.584,1.908-8.633,4.109-15.074,2.055C-205.934,48.68-208.424,43.1-207.837,41.189z"/>
       <path fill="#FFEFBF" d="M-217.351,49.559c-1.171-3.523-0.732-6.458,6.146-8.956c6.88-2.497,12.88-1.177,14.784,1.763
           c10.098,15.273-3.367,8.369-10.393,10.867C-213.692,55.725-216.18,53.084-217.351,49.559z"/>
   </g>
</g>
<g id="left-arm">
   <g>
       <path fill="#FFDCA9" d="M-3.649,45.45c0,5.595-13.104,10.13-29.271,10.13c-16.166,0-29.271-4.535-29.271-10.13
           c0-5.596,13.105-10.132,29.271-10.132C-16.754,35.318-3.649,39.854-3.649,45.45z"/>
       <path fill="#53864B" d="M-3.942,55.434c-15.222,0.443-20.197-14.54-20.197-14.54S25.036,19.458,33.378,8.736
           c13.465-16.885,9.075-35.533,14.343-37.443c12.001-4.403,28.98,28.636,28.98,28.636s7.169,26.726-4.831,37.887
           C52.551,55.58,8.497,54.99-3.942,55.434z"/>
       <path fill="#7AC943" d="M-3.942,52.498c-15.222,0.441-20.197-14.54-20.197-14.54s35.711-6.02,57.225-25.106
           C49.186-1.54,42.599-29.735,47.721-31.643C65.477-36.567,76.263-3.742,76.263-3.742s7.607,27.46-4.393,38.616
           C52.551,52.645,8.497,52.055-3.942,52.498z"/>
       <path fill="#FFEFBF" d="M-23.7,42.366c0.146-5.285-1.903-9.25-12.439-9.401c-10.539-0.291-18.148,4.26-19.466,9.254
           c-6.88,25.553,8.343,9.836,19.024,9.985C-26.188,52.35-23.848,47.799-23.7,42.366z"/>
       <path fill="#FFEFBF" d="M-14.042,44.125c0.147-5.285-1.902-9.251-12.439-9.398c-10.538-0.291-18.148,4.26-19.465,9.254
           c-6.88,25.548,8.342,9.837,19.026,9.984C-16.384,54.113-14.188,49.559-14.042,44.125z"/>
       <path fill="#FFEFBF" d="M9.814,51.76c6-2.785,7.464-5.724-7.172-10.131c-14.636-4.548-30.001-5.282-37.173-3.228
           c-37.322,10.718,1.023,8.664,15.659,13.214C-4.381,56.02,3.813,54.553,9.814,51.76z"/>
       <path fill="#FFEFBF" d="M-4.235,45.888c0.146-5.285-1.903-9.25-12.439-9.397c-10.538-0.295-18.148,4.26-19.465,9.254
           c-6.881,25.548,8.34,9.835,19.024,9.98C-6.577,55.877-4.381,51.32-4.235,45.888z"/>
   </g>
</g>
<g id="keyboard">
   <g>
       <path fill="#191919" d="M-23.236,62.777c-2.78,0.143-109.328-0.148-112.693-0.295c-1.61,0,10.536-4.994,15.074-4.994
           c4.536-0.145,111.669-0.439,113.571-0.291C-5.381,57.197-20.456,62.777-23.236,62.777z"/>
       <path fill="#40484B" d="M1.497,62.92c-0.732,0.152-27.075-0.143-27.808-0.291c-0.438,0,2.636-5.141,3.659-5.141
           c1.171-0.145,27.661-0.439,28.101-0.291C5.888,57.344,2.229,62.777,1.497,62.92z"/>
   </g>
</g>
<g id="monitor">
   <g>
       <path fill="#40484B" d="M-62.282-98.191c-4.978-3.377-193.776,5.433-199.483,9.252c-2.928,1.909,18.733,134.215,26.637,136.708
           c7.903,2.496,197.873,13.363,201.238,9.545C-30.67,53.645-57.307-94.812-62.282-98.191z"/>
       <path fill="#191919" d="M-67.114-94.519c-4.975-3.23-189.237,5.285-194.944,8.957c-2.927,1.908,18.294,130.982,26.052,133.48
           c7.756,2.496,193.335,13.066,196.555,9.396S-62.282-91.291-67.114-94.519z"/>
       <path fill="#40484B" d="M-145.265-19.778c-0.732-2.054-31.175,3.231-32.053,5.433c-0.438,1.177,3.072,80.177,4.243,81.644
           c1.318,1.473,31.759,7.93,32.346,5.729C-140.145,70.824-144.536-17.867-145.265-19.778z"/>
       <path fill="#191919" d="M-151.268-18.015c-0.729-1.908-30.294,3.231-31.172,5.286c-0.438,1.177,2.926,78.118,4.245,79.588
           c1.17,1.469,31.027,7.783,31.467,5.582C-146.436,70.383-150.536-16.106-151.268-18.015z"/>
       <path fill="#191919" d="M-112.776,74.053c-2.342,0.15-91.326-0.291-93.959-0.439c-1.464-0.145,8.781-7.34,12.585-7.49
           c3.658-0.145,93.228-0.734,94.838-0.584C-97.847,65.684-110.434,73.908-112.776,74.053z"/>
       <path fill="#40484B" d="M-93.602,74.203c-0.878,0.145-32.931-0.295-33.955-0.59c-0.439-0.145,3.219-7.635,4.538-7.781
           c1.316-0.148,33.66-0.734,34.246-0.588C-88.188,65.389-92.724,74.053-93.602,74.203z"/>
   </g>
</g>
</svg>;


const logoNoText = <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="370px"
height="364.17px" viewBox="-270.708 -197.136 370 364.17" enableBackground="new -270.708 -197.136 370 364.17"
xmlSpace="preserve" style={{width: width, height: height, margin: '0 auto', display: 'block'}} onMouseEnter={creeperAnimateUp} onMouseLeave={creeperAnimateDown}>
<g id="Layer_14">
</g>
<g id="blue-bg">
<path fill="#29ABE2" d="M90.918-15.05c0,95.695-77.318,173.273-172.697,173.273c-95.379,0-172.699-77.578-172.699-173.273
   c0-95.696,77.32-173.273,172.699-173.273C13.6-188.324,90.918-110.747,90.918-15.05z"/>
</g>
<g id="Creeper">
<g>
   <polygon fill="#39A83F" points="-96.285,115.34 -174.409,126.926 -174.656,-2.377 -98.749,-9.278 		"/>
   <polygon fill="#39A83F" points="-203.122,106.279 -174.409,126.982 -174.471,9.138 -203.213,8.361 		"/>
   <polygon fill="#3D663A" points="-203.213,8.361 -125.366,-0.018 -98.749,5.281 -174.471,12.335 		"/>
   <rect x="-203.122" y="22.285" fill="#36812F" width="29.205" height="14.787"/>
   <rect x="-182.42" y="35.884" fill="#36812F" width="8.503" height="14.788"/>
   <rect x="-203.122" y="33.787" fill="#36812F" width="7.394" height="14.788"/>
   <polygon fill="#36812F" points="-164.675,49.395 -173.917,50.672 -173.917,22.285 -164.675,21.484 		"/>
   <polygon fill="#286426" points="-117.11,95.434 -126.352,97.143 -126.352,59.125 -117.11,58.051 		"/>
   <polygon fill="#286426" points="-126.229,84.154 -135.471,85.311 -135.471,59.553 -126.229,58.824 		"/>
   <polygon fill="#286426" points="-153.769,61.643 -164.675,62.268 -164.675,48.363 -153.769,47.971 		"/>
   <polygon fill="#286426" points="-144.528,73.705 -153.769,74.924 -153.769,47.754 -144.528,46.986 		"/>
   <polygon fill="#56D256" points="-173.27,124.613 -174.78,124.014 -174.78,13.679 -173.054,14.025 		"/>
   <polygon fill="#286426" points="-144.065,100.461 -164.213,101.086 -164.213,87.182 -144.065,86.791 		"/>
   <polygon fill="#286426" points="-144.528,121.779 -153.769,123.764 -153.769,113.982 -144.528,113.707 		"/>
   <polygon fill="#286426" points="-153.769,123.861 -163.011,124.613 -163.011,107.898 -153.769,107.428 		"/>
   <polygon fill="#286426" points="-118.219,31.904 -108.977,31.281 -108.977,45.185 -118.219,45.578 		"/>
   <polygon fill="#286426" points="-136.394,9.607 -127.152,7.9 -127.152,45.916 -136.394,46.99 		"/>
   <polygon fill="#286426" points="-127.276,20.884 -118.034,19.728 -118.034,45.488 -127.276,46.214 		"/>
   <polygon fill="#286426" points="-117.11,31.333 -99.735,30.115 -99.735,57.287 -117.11,58.055 		"/>
   <polygon fill="#286426" points="-108.977,18.029 -99.735,16.873 -99.735,42.63 -108.977,43.359 		"/>
   <polygon fill="#286426" points="-97.072,115.172 -106.313,116.33 -106.313,90.57 -97.072,89.842 		"/>
   <rect x="-182.42" y="89.746" fill="#36812F" width="7.64" height="25.822"/>
   <rect x="-189.628" y="76.195" fill="#36812F" width="7.394" height="14.121"/>
   <polygon fill="#39A83F" points="-91.109,7.992 -185.253,9.468 -185.253,-93.054 -90.616,-93.054 		"/>
   <polygon fill="#39A83F" points="-185.253,9.468 -210.638,0.597 -210.145,-93.054 -185.253,-93.054 		"/>
   <polygon fill="#56D256" points="-184.73,7.482 -186.239,7.003 -186.239,-91.113 -184.515,-90.836 		"/>
   <polyline fill="#191F1D" points="-113.844,-28.883 -113.844,-4.485 -161.533,-4.485 -161.533,-28.883 		"/>
   <rect x="-173.917" y="-66.315" fill="#243E23" width="24.769" height="24.77"/>
   <rect x="-162.826" y="-54.854" fill="#2A2623" width="13.308" height="13.309"/>
   <rect x="-126.229" y="-66.407" fill="#243E23" width="24.769" height="24.768"/>
   <rect x="-126.229" y="-54.946" fill="#2A2623" width="13.309" height="13.307"/>
   <rect x="-161.533" y="-28.977" fill="#2A4029" width="12.384" height="12.385"/>
   <rect x="-126.229" y="-29.069" fill="#2A4029" width="12.384" height="12.385"/>
   <rect x="-149.518" y="-41.176" fill="#2A4029" width="23.29" height="12.385"/>
   <rect x="-161.533" y="-4.393" fill="#2A4029" width="12.384" height="12.385"/>
   <rect x="-126.229" y="-4.485" fill="#2A4029" width="12.384" height="12.385"/>
   <rect x="-139.414" y="-79.375" fill="#337D2E" width="13.062" height="13.061"/>
   <rect x="-186.609" y="-79.375" fill="#337D2E" width="13.061" height="13.061"/>
   <rect x="-172.438" y="-92.438" fill="#337D2E" width="13.061" height="13.063"/>
   <rect x="-113.844" y="-41.516" fill="#337D2E" width="13.062" height="13.063"/>
   <rect x="-113.844" y="-5.165" fill="#337D2E" width="13.062" height="13.064"/>
   <rect x="-104.54" y="-92.438" fill="#337D2E" width="13.061" height="13.063"/>
   <polygon fill="#337D2E" points="-195.728,-7.536 -199.794,-9.014 -199.794,-46.354 -195.728,-46.106 		"/>
   <polygon fill="#337D2E" points="-199.794,-20.29 -201.766,-21.336 -201.766,-33.661 -199.794,-33.352 		"/>
   <polygon fill="#337D2E" points="-201.889,-56.825 -207.064,-58.057 -207.064,-70.812 -201.889,-69.887 		"/>
   <polygon fill="#337D2E" points="-186.979,-6.118 -189.443,-7.167 -189.443,-19.489 -186.979,-19.18 		"/>
   <polygon fill="#337D2E" points="-196.036,-68.963 -198.5,-70.01 -198.5,-82.334 -196.036,-82.025 		"/>
   <polygon fill="#337D2E" points="-190.06,-68.963 -192.524,-70.01 -192.524,-82.334 -190.06,-82.025 		"/>
   <polygon fill="#337D2E" points="-206.572,2.291 -209.037,1.246 -209.037,-11.079 -206.572,-10.77 		"/>
</g>
</g>
<g id="body">
<g>
   <path fill="#5C9732" d="M42.185,76.434l-118.548-9.84c0,0-3.367-87.077,32.197-85.167c35.565,1.908,43.905,13.655,86.351-1.909
       c17.563-3.965,26.782,9.251,28.833,16.59C73.064,3.455,42.185,76.434,42.185,76.434z"/>
   <path fill="#7AC943" d="M23.158,60.279l-112.986-6.314c0,0,2.342-80.617,19.026-86.342c33.808-11.454,51.518,11.454,93.96-4.258
       C40.721-40.6,62.673-27.972,64.576-20.63S23.158,60.279,23.158,60.279z"/>
   <path fill="#6E7280" d="M-11.528-17.253l-17.855,70.927l1.757-70.487L-11.528-17.253z"/>
   <path fill="#DCE4FF" d="M-10.064-17.253L-27.92,53.674l1.755-70.487L-10.064-17.253z"/>
</g>
</g>
<g id="desk">

   <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="-100.9028" y1="-40.4302" x2="-82.3258" y2="49.7234" gradientTransform="matrix(1 0 0 -1.0033 0 96.692)">
   <stop  offset="0" style={{stopColor:'#DDC4A7'}}/>
   <stop  offset="1" style={{stopColor:'#C69C6D'}}/>
</linearGradient>
<path fill="url(#SVGID_1_)" d="M75.845,55.877l-318.468-7.787c25.172,64.469,87.667,110.133,160.844,110.133
   C-11.675,158.223,48.77,116.227,75.845,55.877z"/>
</g>
<g id="head">
<g>
   <path fill="#FFDCA9" d="M-34.214-132.524L-55.5-111.5c0,0,8.137,62.033,10.333,84.5C-40-19.833-30.994-12.701-10.651-12.701
       c20.345,0,50.934-12.48,51.663-14.685c0.732-2.202,24.588-65.344,20.93-74.155C58.283-110.349-34.214-132.524-34.214-132.524z"/>
   <path fill="#FFDCA9" d="M61.198-69.185c-0.458,4.926-3.179,8.699-6.076,8.427c-2.896-0.271-4.875-4.483-4.417-9.409
       s3.178-8.699,6.074-8.427C59.677-78.324,61.655-74.111,61.198-69.185z"/>
   <path fill="#FFEFBF" d="M-35.678-135.459c-12.322,1.626-19.155,15.793-22.155,21.293s4.081,34.421,3.854,38.831
       C-55.667-42.5-46.833-44.667-47.167-27.5c0.732,7.193,14.708,11.862,35.053,11.862c20.343,0,50.931-12.481,51.663-14.683
       c0.732-2.204,24.588-65.346,20.93-74.155C56.818-113.288,51.5-148.833-35.678-135.459z"/>
   <path fill="#FFEFBF" d="M60.423-70.393c-0.456,4.926-3.176,8.699-6.074,8.429c-2.896-0.271-4.874-4.485-4.417-9.411
       c0.458-4.924,3.179-8.697,6.074-8.427C58.904-79.532,60.881-75.319,60.423-70.393z"/>
   <path fill="#42210B" d="M-24.993-159.69l-15.221-4.403l-30.588,7.341l20.488,10.279l-36.589,4.406l24.882,12.481l-33.662,13.948
       l24.148,4.406l9.514,4.847l8.771-4.366l4.25,1.875l8.625-5.375l4.1,2l8.355-6.75l3.513,3l8.488-3.308l3.323,4.933L-0.625-121
       l4.125,7.5l9-8.333L20.333-113l3.5-5L29-109l4.167-5.5l6.5,11.833l6.458-3.208L48.5-93.529l3.75,1.555L53.875-87
       c0,0-1.153,12.763,0.458,10.333c2.688-4.05,4.846-0.742,4.846-0.742l5.542-30.885l15.368-25.698c0,0-19.759-1.468-24.149-1.468
       c-4.39,0-18.294-22.761-18.294-22.761l-24.881,2.202l3.66-11.747L-24.993-159.69z"/>
   <path fill="#42210B" d="M15.107-48.677c0.147,0.735-11.562,5.728-23.855,7.93c-10.245,1.908-25.904,2.643-26.05,1.908
       c-0.147-0.734,16.098-3.817,26.343-5.726C1.79-46.474,14.962-49.41,15.107-48.677z"/>
   <path fill="#EDC085" d="M-14.017-65.565c0,0.296-2.487,1.322-5.122,1.322c-2.049,0-5.269-0.881-5.269-1.175
       c0-0.293,3.365-0.293,5.414-0.293C-16.797-65.71-14.017-65.858-14.017-65.565z"/>
   <path fill="#42210B" d="M-33.336-67.913c0,0.242-0.196,0.439-0.438,0.439s-0.439-0.197-0.439-0.439c0-0.243,0.197-0.44,0.439-0.44
       S-33.336-68.156-33.336-67.913z"/>
</g>
</g>
<g id="left-eye">
<path fill="#42210B" d="M10.717-79.662c1.464-5.726-0.584-11.158-4.391-12.186c-3.804-0.882-8.049,2.936-9.513,8.664
   c-1.463,5.726-3.952,9.543,4.391,12.186C10.717-68.06,9.4-73.933,10.717-79.662z"/>
</g>
<g id="left-brow">
<path fill="#42210B" d="M15.84-90.234c-1.61-5.726-6.585-11.6-12.879-7.342c-4.829,3.377,3.072,0.294,7.172,3.377
   C14.084-91.409,17.449-84.505,15.84-90.234z"/>
</g>
<g id="right-eye">
<path fill="#42210B" d="M-30.262-86.269c-1.61-5.726-6.001-9.397-9.807-8.368c-3.804,1.026-5.562,6.607-3.804,12.333
   c1.609,5.729,1.316,10.279,9.804,8.371C-24.555-76.135-28.652-80.541-30.262-86.269z"/>
</g>
<g id="right-brow">
<path fill="#42210B" d="M-49.288-93.169c1.609-5.729,6.587-11.602,12.878-7.342c4.832,3.377-3.071,0.293-7.171,3.377
   C-47.533-94.344-50.898-87.444-49.288-93.169z"/>
</g>
<g id="right-arm">
<g>
   <path fill="#53864B" d="M-199.788,62.777c-15.221,0.438-20.197-14.539-20.197-14.539s26.197-6.458,47.713-25.549
       c16.099-14.392,86.746-51.517,92.015-53.425c12.001-4.406-3.451,38.402-15.452,49.563
       C-115.029,36.597-187.347,62.334-199.788,62.777z"/>
   <path fill="#7AC943" d="M-195.397,56.9c-15.221,0.443-20.197-14.535-20.197-14.535s26.197-6.462,47.713-25.553
       C-151.783,2.425-86.11-29.41-80.841-31.319c12.001-4.405,4.712,31.118-7.289,42.274C-107.449,28.725-182.956,56.463-195.397,56.9z
       "/>
   <path fill="#FFDCA9" d="M-186.323,52.789c0,5.6-13.105,10.131-29.271,10.131c-16.167,0-29.27-4.531-29.27-10.131
       c0-5.592,13.104-10.132,29.27-10.132C-199.429,42.657-186.323,47.197-186.323,52.789z"/>
   <path fill="#FFEFBF" d="M-240.035,50.148c-0.878-2.645-0.586-4.847,4.684-6.757c5.121-1.906,9.659-0.878,11.123,1.324
       c7.462,11.453-2.489,6.167-7.758,8.074C-237.254,54.699-239.012,52.789-240.035,50.148z"/>
   <path fill="#FFEFBF" d="M-228.912,48.238c-1.171-3.228-0.732-6.167,5.854-8.517c6.585-2.35,12.146-1.029,13.904,1.615
       c9.511,14.388-3.22,7.783-9.661,10.132C-225.399,53.965-227.741,51.469-228.912,48.238z"/>
   <g>
       <path fill="#191919" d="M-189.25,63.807c-3.66,0.291-62.64-0.443-66.885-0.734c-2.194-0.152,13.611-10.133,19.172-11.457
           c18.441-4.55,37.906-1.172,40.395-0.732C-194.227,51.029-185.592,63.512-189.25,63.807z"/>
       <path fill="#40484B" d="M-189.25,63.807c-3.66,0.291-15.807-10.133-20.051-10.428c-2.194-0.146-33.223-0.295-27.662-1.764
           c18.441-4.55,37.906-1.172,40.395-0.732C-194.227,51.029-185.592,63.512-189.25,63.807z"/>
   </g>
   <path fill="#FFEFBF" d="M-205.35,63.363c1.316,2.201,9.952,2.496,18.733-0.291c8.928-2.793,9.074-6.318,7.757-8.373
       c-9.367-15.417-9.513-1.76-18.441,1.025C-205.934,58.664-206.667,61.162-205.35,63.363z"/>
   <path fill="#FFEFBF" d="M-207.837,41.189c0.584-1.906,6.293-1.763,12.733,0.439c6.439,2.058,11.123,5.437,10.536,7.346
       c-0.584,1.908-8.633,4.109-15.074,2.055C-205.934,48.68-208.424,43.1-207.837,41.189z"/>
   <path fill="#FFEFBF" d="M-217.351,49.559c-1.171-3.523-0.732-6.458,6.146-8.956c6.88-2.497,12.88-1.177,14.784,1.763
       c10.098,15.273-3.367,8.369-10.393,10.867C-213.692,55.725-216.18,53.084-217.351,49.559z"/>
</g>
</g>
<g id="left-arm">
<g>
   <path fill="#FFDCA9" d="M-3.649,45.45c0,5.595-13.104,10.13-29.271,10.13c-16.166,0-29.271-4.535-29.271-10.13
       c0-5.596,13.105-10.132,29.271-10.132C-16.754,35.318-3.649,39.854-3.649,45.45z"/>
   <path fill="#53864B" d="M-3.942,55.434c-15.222,0.443-20.197-14.54-20.197-14.54S25.036,19.458,33.378,8.736
       c13.465-16.885,9.075-35.533,14.343-37.443c12.001-4.403,28.98,28.636,28.98,28.636s7.169,26.726-4.831,37.887
       C52.551,55.58,8.497,54.99-3.942,55.434z"/>
   <path fill="#7AC943" d="M-3.942,52.498c-15.222,0.441-20.197-14.54-20.197-14.54s35.711-6.02,57.225-25.106
       C49.186-1.54,42.599-29.735,47.721-31.643C65.477-36.567,76.263-3.742,76.263-3.742s7.607,27.46-4.393,38.616
       C52.551,52.645,8.497,52.055-3.942,52.498z"/>
   <path fill="#FFEFBF" d="M-23.7,42.366c0.146-5.285-1.903-9.25-12.439-9.401c-10.539-0.291-18.148,4.26-19.466,9.254
       c-6.88,25.553,8.343,9.836,19.024,9.985C-26.188,52.35-23.848,47.799-23.7,42.366z"/>
   <path fill="#FFEFBF" d="M-14.042,44.125c0.147-5.285-1.902-9.251-12.439-9.398c-10.538-0.291-18.148,4.26-19.465,9.254
       c-6.88,25.548,8.342,9.837,19.026,9.984C-16.384,54.113-14.188,49.559-14.042,44.125z"/>
   <path fill="#FFEFBF" d="M9.814,51.76c6-2.785,7.464-5.724-7.172-10.131c-14.636-4.548-30.001-5.282-37.173-3.228
       c-37.322,10.718,1.023,8.664,15.659,13.214C-4.381,56.02,3.813,54.553,9.814,51.76z"/>
   <path fill="#FFEFBF" d="M-4.235,45.888c0.146-5.285-1.903-9.25-12.439-9.397c-10.538-0.295-18.148,4.26-19.465,9.254
       c-6.881,25.548,8.34,9.835,19.024,9.98C-6.577,55.877-4.381,51.32-4.235,45.888z"/>
</g>
</g>
<g id="keyboard">
<g>
   <path fill="#191919" d="M-23.236,62.777c-2.78,0.143-109.328-0.148-112.693-0.295c-1.61,0,10.536-4.994,15.074-4.994
       c4.536-0.145,111.669-0.439,113.571-0.291C-5.381,57.197-20.456,62.777-23.236,62.777z"/>
   <path fill="#40484B" d="M1.497,62.92c-0.732,0.152-27.075-0.143-27.808-0.291c-0.438,0,2.636-5.141,3.659-5.141
       c1.171-0.145,27.661-0.439,28.101-0.291C5.888,57.344,2.229,62.777,1.497,62.92z"/>
</g>
</g>
<g id="monitor">
<g>
   <path fill="#40484B" d="M-62.282-98.191c-4.978-3.377-193.776,5.433-199.483,9.252c-2.928,1.909,18.733,134.215,26.637,136.708
       c7.903,2.496,197.873,13.363,201.238,9.545C-30.67,53.645-57.307-94.812-62.282-98.191z"/>
   <path fill="#191919" d="M-67.114-94.519c-4.975-3.23-189.237,5.285-194.944,8.957c-2.927,1.908,18.294,130.982,26.052,133.48
       c7.756,2.496,193.335,13.066,196.555,9.396S-62.282-91.291-67.114-94.519z"/>
   <path fill="#40484B" d="M-145.265-19.778c-0.732-2.054-31.175,3.231-32.053,5.433c-0.438,1.177,3.072,80.177,4.243,81.644
       c1.318,1.473,31.759,7.93,32.346,5.729C-140.145,70.824-144.536-17.867-145.265-19.778z"/>
   <path fill="#191919" d="M-151.268-18.015c-0.729-1.908-30.294,3.231-31.172,5.286c-0.438,1.177,2.926,78.118,4.245,79.588
       c1.17,1.469,31.027,7.783,31.467,5.582C-146.436,70.383-150.536-16.106-151.268-18.015z"/>
   <path fill="#191919" d="M-112.776,74.053c-2.342,0.15-91.326-0.291-93.959-0.439c-1.464-0.145,8.781-7.34,12.585-7.49
       c3.658-0.145,93.228-0.734,94.838-0.584C-97.847,65.684-110.434,73.908-112.776,74.053z"/>
   <path fill="#40484B" d="M-93.602,74.203c-0.878,0.145-32.931-0.295-33.955-0.59c-0.439-0.145,3.219-7.635,4.538-7.781
       c1.316-0.148,33.66-0.734,34.246-0.588C-88.188,65.389-92.724,74.053-93.602,74.203z"/>
</g>
</g>
</svg>;

    return withText ? logoWithText : logoNoText

}