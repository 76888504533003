import { createContext, useReducer  } from 'react';
import { PropTypes, ActionTypes, StateTypes } from './state-types';
import { InitialState } from './initial-state';
const Cookies = require('js-cookie');

let AppContext = createContext({});

let reducer = (state: StateTypes, action: ActionTypes): StateTypes => {
    switch(action.type){
      
      
      case 'toggleDrawer':
        return {
          ...state,
          drawers: {
            ...state.drawers,
            [action.payload.anchor]: action.payload.open
          }
        }

        case 'togglePrefersReducedMotion':
          Cookies.set('prefersReducedMotion', !state.prefersReducedMotion, { expires: 90, path: '/' });
          return {
            ...state,
            prefersReducedMotion: !state.prefersReducedMotion
          }

      default:
        return {
            ...state
        }
    }
}

function AppContextProvider(props: PropTypes){
    const appState = {
        ...InitialState
    }

    let [state, dispatch] = useReducer<any>(reducer, appState);

    let value = {state, dispatch};

    return(
        <AppContext.Provider value={value}>
            {props.children}
        </AppContext.Provider>
    )
}

export {AppContext, AppContextProvider}