import React, {useEffect, useState, useRef, useContext} from 'react';
import Isotope from 'isotope-layout';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { AppContext } from '../state/State';

interface IsotopeFeatureProps {
  isotopeItems: JSX.Element[]
}

export const IsotopeFeature = ({isotopeItems}: IsotopeFeatureProps) => {
  const { state, dispatch } = useContext<any>(AppContext);
  const { prefersReducedMotion } = state;
  
  const isotopeElement = useRef<any>(null);
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState('*');
  const [sortKey, setSortKey] = useState('most-recent');

  //initialize an Isotope object with configs
  useEffect(() => {
    isotopeElement.current = new Isotope('.isotope-container', {
      transitionDuration: !prefersReducedMotion ? 500 : 0,
      itemSelector: '.filter-item',
      layoutMode: 'fitRows',
      getSortData: {
				title: '.profile-title'
			},
    });
    // cleanup
    return () => {
      if(isotopeElement.current && isotopeElement.current.destroy){
        isotopeElement.current.destroy();
      }
    }
  }, [prefersReducedMotion]);

  // handling filter key change
  useEffect(() => {
    filterKey === '*'
      ? isotopeElement.current.arrange({filter: `*`})
      : isotopeElement.current.arrange({filter: `.${filterKey}`})
  }, [filterKey]);

  useEffect(() => {
    sortKey === 'most-recent'
      ? isotopeElement.current.arrange({sortBy: `original-order`})
      : isotopeElement.current.arrange({sortBy: `${sortKey}`})
  }, [sortKey]);

  const handleFilterKeyChange = (key: string) => () => {setFilterKey(key); console.log('key', key)};
  const handleSortKeyChange = (key: string) => () => setSortKey(key);

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFilterKey(event.target.value as string);
  };

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
  }));

  return (
    <>
<Grid container spacing={2}>
  <Grid item xs={8}>
  <InputLabel sx={{
    display: {
      xs: 'none',
      sm: 'inline'
    },
    fontSize: '12px'
  }}>Sort by:</InputLabel>
      <ButtonGroup variant="outlined" color="secondary" aria-label="outlined primary button group" sx={{ml:1, height: '40px', whiteSpace: 'nowrap'}}>
        <Button onClick={handleSortKeyChange('most-recent')} variant={sortKey === 'most-recent' ? 'contained' : 'outlined'}>Most Recent</Button>
        <Button onClick={handleSortKeyChange('title')} variant={sortKey !== 'most-recent' ? 'contained' : 'outlined'}>Title</Button>
      </ButtonGroup>
    
  </Grid>
  <Grid item xs={4}>
    <FormControl fullWidth>
    <InputLabel id="project-filter-select-label">Project</InputLabel>
    <Select
      labelId="project-filter-select-label"
      id="project-filter-select"
      value={filterKey}
      label="Projects"
      onChange={handleSelectChange}
      size="small"
    >
      <MenuItem value="*">All</MenuItem>
      <MenuItem value="e3">E3</MenuItem>
      <MenuItem value="gamepro">GamePro Media</MenuItem>
      <MenuItem value="microsoft">Microsoft</MenuItem>
      <MenuItem value="oldnavy">Old Navy</MenuItem>
      <MenuItem value="macworld">MacWorld</MenuItem>
      <MenuItem value="gamemarketingsummit">Game Marketing Summit</MenuItem>
    </Select>
  </FormControl>
  </Grid>
</Grid>


      {/* <ul>
        <li onClick={handleFilterKeyChange('*')}>Show Both</li>
        <li onClick={handleFilterKeyChange('vege')}>Show Veges</li>
        <li onClick={handleFilterKeyChange('fruit')}>Show Fruits</li>
      </ul> */}
      <hr />
      <div className="isotope-container" ref={isotopeElement}>
        {
          isotopeItems.map((isotopeItem: JSX.Element) => 
            isotopeItem
          )
        }
      </div>
    </>
  )
}
