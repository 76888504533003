const Cookies = require('js-cookie');

const prefersReducedMotion = window.matchMedia(`(prefers-reduced-motion: reduce)`).matches

export const InitialState = {
  
  prefersReducedMotion: prefersReducedMotion || Cookies.get('prefersReducedMotion') === "true" ? true : false,
  drawers: {
    left: false
  },
  // currentViewPort: Cookies.get('currentViewport') || "mobile2",
  // previewDimensions: Cookies.get('previewDimensions') ? JSON.parse(Cookies.get('previewDimensions')) : {width: '1440px', height: '400px'}
}