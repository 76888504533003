import React, { useContext, useEffect } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';

import ThatDevGuyAvatar from '../static/images/that-dev-guy-avatar.jpg'
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';

import { enterAnimation, exitAnimation } from '../components/page-transition';

import './resume.css';
import ThatDevGuyResume from '../static/christopher-lee-thatdevguy-resume-2023.pdf';
import { AppContext } from '../state/State';

const pageId = 'resume-content';

export const Resume = () => {
  const { state } = useContext<any>(AppContext);
  const { prefersReducedMotion } = state;
  
  //PageTransition Animation
  useEffect(() => {
    if(!prefersReducedMotion){
      enterAnimation(pageId);
      window.addEventListener('beforeunload', function(){
        console.log('before unload');
        exitAnimation(pageId);
      })
      return () => {
        window.removeEventListener('beforeunload', () => {
          exitAnimation(pageId);
        })
      };
    }
  },[prefersReducedMotion]);

    const theme = useTheme();
    const ResumeSection = (props: React.PropsWithChildren<{}>) => {
        return <Typography variant="h4" color="secondary" sx={{pt: .5, borderLeft: `4px solid ${theme.palette.secondary.main}`, fontSize: '20px', pl: .5, minHeight: '24px', mb: 2}}>{props.children}</Typography>
    }
    const ResumePosition = (props: React.PropsWithChildren<{}>) => {
      return <Typography variant="h5" sx={{fontSize: '20px', color: '#0A66C2'}}>{props.children}</Typography>
    }
    const ResumeCompany = (props: React.PropsWithChildren<{}>) => {
      return <Typography variant="body1" sx={{fontSize: '16px', color: '#37474F', mb: .5}}>{props.children}</Typography>
    }
    const ResumeBody = (props: React.PropsWithChildren<{}>) => {
      return <Typography variant="body1" sx={{fontSize: '14px', color: '#000', mb: 4}}>{props.children}</Typography>
    }
    const ResumeAsideItem = (props: React.PropsWithChildren<{}>) => {
      return <Typography variant="body1" sx={{fontSize: '14px', color: '#000', mb: 3}}>{props.children}</Typography>
    }

    return <div id={pageId} className="page-container">
      <div className="title-bar">
        <Typography variant="h4" component="h1" align="center" color="secondary">
          My Resume
        </Typography>
      </div>
      <Container disableGutters maxWidth="xl" component="main" sx={{ pt: 0, pb: 6 }}>
        <Box>
            <div className="button-wrapper"><Button className="download-resume-btn" color="secondary" variant="contained" href={ThatDevGuyResume} target="_blank">Download Resume</Button></div>

            <Box id="resume">
                <Grid container>
                    <Grid item xs={12}>

                        <Grid container sx={{alignItems: 'center'}}>
                            <Grid className="avatar-and-title" item xs={12} sm={6}>
                                <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'stretch'}}>
                                    <Avatar alt="Christopher Lee (aka ThatDevGuy)" src={ThatDevGuyAvatar} sx={{ width: 90, height: 90, mr: 2 }} />
                                    <div className="name-and-title">
                                        <Typography variant="h2" color="secondary">Christopher Lee</Typography>
                                        <Typography variant="body1" sx={{mt: '-8px', fontSize: '20px', color: '#666', lineHeight: 1.1}}>Fullstack Web Developer and UI/UX Designer</Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid className="contact-info" item xs={12} sm={6}>
                                <div>Profile: <Link href="https://thatdevguy.com/portfolio" target="_blank">https://thatdevguy.com/portfolio</Link></div>
                                <div>LinkedIn: <Link href="https://linkedin.com/in/thatdevguy" target="_blank">https://linkedin.com/in/thatdevguy</Link></div>
                                <div>Email: <Link href="mailto:chris@thatdevguy.com">chris@thatdevguy.com</Link></div>
                                {/* <div>Phone: <Link href="tel:415-533-9090">415-533-9090</Link></div> */}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Divider sx={{width: '100%', height: '1px', my: 3, color: '#D9D9D9'}} />

                    <Grid item xs={12}>
                      <Grid container className="resume-content">
                        <Grid item xs={12} sm={9.5} className="resume-main-body">
                          <ResumeSection>Summary</ResumeSection>
                          <ResumeBody>
                            Specializing in developing custom solutions that meet clients’ needs.  A well-rounded developer and designer with experience on all major aspects of websites – from conceptualizing an idea for a tool or component, to server infrastructure, to programming dynamic and interactive marketing content, to designing easy to use user interfaces and promotional graphics.
                          </ResumeBody>

                          <ResumeSection>Experience</ResumeSection>
                          
                          <ResumePosition>Lead Web Developer</ResumePosition>
                          <ResumeCompany>Old Navy / GAP Inc. | October 2018 – May 2023</ResumeCompany>
                          <ResumeBody>
                            During my time with Gap, I assisted across all the brands (Gap, Banana Republic and Athleta, with a primary focus on Old Navy) with developing React components, marketing campaigns and contributed to their new CMS.  In my role as Lead Web Developer, I developed proof of concepts and prototypes for user interfaces and tools that boosted efficiency in building promotional content such as homepages, category pages, promos and landing pages. I had also conceptualized and developed a Node.js API and React app that enabled producers to generate marketing content dynamically for Gap’s Content Management System.
                          </ResumeBody>

                          <ResumePosition>Chief Technology Officer</ResumePosition>
                          <ResumeCompany>ERATE.com | July 2016 – September 2018</ResumeCompany>
                          <ResumeBody>
                            As the CTO, I was tasked to modernize the consumer facing website allowing it to be viewed securely and responsively on mobile devices. The process also involved developing a tool to convert over 1000+ articles from the old static website so that they could be stored onto a database. I also created a custom content management system for the company to easily import lender information to the website which assisted in a huge increase in SERP (search engine result pages) to be indexed by Google. My other responsibilities involved rebranding, website enhancements, widget development, mobile app development, maintaining and configuring backend servers, troubleshooting technical issues, website analytics, page speed optimization, and ad operations.
                          </ResumeBody>

                          <ResumePosition>Lead Web Developer</ResumePosition>
                          <ResumeCompany>IDG | December 2011 – July 2016</ResumeCompany>
                          <ResumeBody>
                            IDG World Expo, along with the ESA, ran one of the largest video gaming events known as E3. In this role, I designed, maintained and enhanced the main website and developed a Content Management System from the ground up that streamlined the process for editors as well as exhibitors to efficiently input content into the consumer facing website. I also lead a complete migration of all their website properties when IDG wanted to part ways with the ESA. I had also developed and maintained several marketing websites using Wordpress for events that IDG World Expo produced such as MacWorld/iWorld, MacIT Conference, Game Marketing Summit, DEW Expo, Techmanity, and many more.
                          </ResumeBody>

                          <ResumePosition>Senior Frontend Web Developer</ResumePosition>
                          <ResumeCompany>GamePro Media | April 2010 – December 2011</ResumeCompany>
                          <ResumeBody>
                            GamePro was the publisher of one of the most popular video gaming magazines. I was hired on to enhance the design of the website and create custom user interfaces for visitors to interact with the website as well as enhancing the Content Management System so that editors could easily enter in content into the website. The redesign and increase in content to the website lead to a major increase in viewership (11 million views in our final month). However, GamePro met its demise at the end of 2011, impacted by the “print vs digital” effect.
                          </ResumeBody>

                          <ResumePosition>Frontend Web Developer</ResumePosition>
                          <ResumeCompany>Hornblower Cruises & Events | January 2009 – April 2010</ResumeCompany>
                          <ResumeBody>
                            In this role, I was part of the marketing team. I had been involved in several campaigns to boost sales and redesigned several of their websites to make it more user friendly and visually appealing. This involved interfacing with their SQL Database and developing with .NET. I also enhanced several of their online forms and assisted with their content management system.
                          </ResumeBody>

                        </Grid>
                        <Grid item xs={12} sm={.5} sx={{position: 'relative'}}><div style={{position: 'absolute', width: '1px', height: '100%', background: '#ccc', left: '50%', top: '0', color: '#D9D9D9'}}></div></Grid>
                        <Grid item xs={12} sm={2} className="resume-side-bar">
                          <ResumeSection>Developer Skills</ResumeSection>
                          <ResumeAsideItem>
                              React, TypeScript, React Native, Redux, Angular, Testing Library, Jest
                          </ResumeAsideItem>
                          <ResumeAsideItem>
                            Responsive Web Design, HTML5/CSS3/JavaScript, SASS, Ionic Framework, MaterialUI, jQuery, Bootstrap, Zurb Foundation
                          </ResumeAsideItem>
                          <ResumeAsideItem>
                            Node, Express, PHP, ColdFusion, Lucee
                          </ResumeAsideItem>
                          <ResumeAsideItem>
                            MySQL, Firebase, MongoDB, GraphQL
                          </ResumeAsideItem>
                          <ResumeAsideItem>
                            AWS, Azure, Rackspace
                          </ResumeAsideItem>
                          <ResumeAsideItem>
                            Amplience, Wordpress, Joomla, Drupal
                          </ResumeAsideItem>

                          <ResumeSection>Product and Website Design Skills</ResumeSection>
                          <ResumeAsideItem>
                            Adobe Photoshop / Illustrator / XD / Animate
                          </ResumeAsideItem>
                          <ResumeAsideItem>
                            Figma, Miro, Zepplin, Sketch
                          </ResumeAsideItem>


                          <ResumeSection>Education</ResumeSection>
                          <ResumeAsideItem>
                            Associate Degree in Networking Technology<br />
                            Heald College School of Technology<br />
                            GPA: 4.00, Dean’s List
                          </ResumeAsideItem>
                          <ResumeAsideItem>
                            Associate Degree in Electronics Technology<br />
                            Heald College School of Technology<br />
                            GPA: 4.00, Dean’s List
                          </ResumeAsideItem>
                          <ResumeAsideItem>
                            A+ Certified Technician
                          </ResumeAsideItem>
                          <ResumeAsideItem>
                            Microsoft Certified Solutions Expert
                          </ResumeAsideItem>
                          
                        </Grid>
                      </Grid>
                    </Grid>

                    <Divider sx={{width: '100%', height: '1px', my: 3, color: '#D9D9D9'}} />
                    <Grid item xs={12}>
                      <Typography variant="body2" sx={{fontSize: '14px', color: '#666', textAlign: 'center', fontStyle: 'italic', mb: 2}}>
                        “A proactive web developer with a wealth of experience who seeks out to improve processes<br />and approaches huge challenges in a calm and collected manner.”
                      </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>        
      </Container>
    </div>
}


