import React from 'react';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface CloseableAlertProps {
  children: JSX.Element | string;
  severity: 'info' | 'warning' | 'error';
}

export const CloseableAlert = (props: CloseableAlertProps) => {
  const [alertOpen, setAlertOpen] = React.useState(true);
  const { children, severity } = props;

  return <Collapse in={alertOpen}>
  <Alert severity={severity} sx={{p:2, justifyContent: 'center', mb: 4}} action={
      <IconButton
        aria-label="close"
        color="inherit"
        size="small"
        onClick={() => {
          setAlertOpen(false);
        }}
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
    }>
      {children}
    </Alert>
  </Collapse>
}