import React from 'react';
import {
  BrowserRouter as Router,
  Link as RouterLink,
  Routes,
  Route
} from "react-router-dom";
import { Home, About, Portfolio, Resume, PrivacyPolicy } from './pages';
import { AppContextProvider } from './state/State';


import { createTheme, ThemeProvider } from '@mui/material/styles';


import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';


import Footer from './template/footer';
import Header from './template/header';
import Menu, {drawerWidth} from './template/menu';


import './App.css';

const theme = createTheme(
  {
    typography: {
      fontFamily: [
        '"Noto Sans"', 'sans-serif',
      ].join(','),
      h1: {
        fontFamily: [
          '"Bebas Neue"', 'cursive',
        ].join(','),
      },
      h2: {
        fontFamily: [
          '"Bebas Neue"', 'cursive',
        ].join(','),
      },
      h3: {
        fontFamily: [
          '"Bebas Neue"', 'cursive',
        ].join(','),
      },
      h4: {
        fontFamily: [
          '"Bebas Neue"', 'cursive',
        ].join(','),
      },
      h5: {
        fontFamily: [
          '"Bebas Neue"', 'cursive',
        ].join(','),
      },
      h6: {
        fontFamily: [
          '"Bebas Neue"', 'cursive',
        ].join(','),
      },
    },
    palette: {
      primary: {
        main: '#24c0f0',
      },
      secondary: {
        main: '#1b1464',
      },
      error: {
        main: '#2c3f8e',
      },
    },
  }
);

function App() {
  return (
    <AppContextProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Menu />
            <Box
              component="main"
              sx={{ flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
              
              <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                {/* <Header /> */}
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/portfolio" element={<Portfolio />} />
                  <Route path="/resume" element={<Resume />} />
                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                </Routes>
                {/* <Footer /> */}
              </Box>
            </Box>
          </Box>
        </Router>
      </ThemeProvider>
    </AppContextProvider>
  );
}

export default App;