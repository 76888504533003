export const itemData = [
  {
    img: '/images/portfolio-cube/thatdevguy-website.jpg',
    description: 'My personal portfolio website designed and developed from scratch.',
    title: 'ThatDevGuy',
    filter: 'thatdevguy portfolio',
    content: <div>
      <div className="visual-gallery">
        <div className="gallery-images">
          <img src="/images/portfolio/websites/figma-prototyping.jpg" alt="Screenshot of prototyping in Figma" />
        </div>
        <div className="gallery-description">
          <p>Prototyping ThatDevGuy in Figma</p>
        </div>
      </div>
      <p>So this is my personal portfolio website.  I figured I should finally put something up on the internet to validate what I do for my career.  It's funny, but there's actually quite a lot of web developers out there that still don't have their own website!  Can you believe that?  Now I'm no longer one of them.  Yay!</p>
      <p>Anyhow, I love being creative and whip out the ol' Photoshop once in a while.  This site was first wire-framed and then prototyped using Figma.  I put together a rather simple style guide and started up a React project.  Then to give it some life, I scripted some animation pieces in and grabbed screenshots of my old work.  And there you have it, my portfolio website!</p>
      <p className="skillsets-used">React, TypeScript, GSAP, Illustrator, Photoshop, Figma</p>
    </div>
  },
  {
    img: '/images/portfolio-cube/old-navy-websites.jpg',
    description: 'My most recent position, developing for Gap Inc. and Old Navy',
    title: 'Old Navy / Gap Inc.',
    filter: 'oldnavy gap ecommerce',
    content: <div>
      <div className="visual-gallery">
        <div className="gallery-images">
          <img src="/images/portfolio/websites/old-navy-womens-denim-landing-page-2022_sm.jpg" alt="Screenshot of Old Navy - Women's Denim" />
        </div>
        <div className="gallery-description">
          <p>Created high value interactive marketing pages for Old Navy</p>
        </div>
      </div>
      <p>My most recent position was with Gap Inc. Although I was primarily focused on the Old Navy brand, I worked across different MicroFrontend (MFE) teams to develop and document new React components coded using TypeScript.  I was also brought on to assist with their headless CMS initiatives.  When I wasn't working on the backend, I was pulled in to create high value interactive website campaigns.</p>
      <p>If you happen to be looking for Women's Jeans, feel free to check out this <a href="https://oldnavy.gap.com/browse/info.do?cid=1185579" target="_blank">interactive fit guide</a> we developed to make sure you get the right fit and style!</p>
      <p className="skillsets-used">HTML/CSS/Javascript, React, TypeScript, Node, Testing Library, Jest, jQuery, Amplience, GSAP, Illustrator, Photoshop, Figma, Zepplin, Miro, Optimizely A/B Testing</p>
    </div>
  },
  {
    img: '/images/portfolio-cube/erate-mobile-app.jpg',
    description: 'A resource where you can compare rates & lenders all in one place.',
    title: 'ERATE®',
    filter: 'erate mortgage auto loan rates',
    content: <div>
      <div className="visual-gallery">
        <div className="gallery-images">
          <img src="/images/portfolio/websites/erate-website_sm.jpg" alt="Screenshot of ERATE.com" />
        </div>
        <div className="gallery-description">
          <p>Rebranded and redesigned ERATE.com</p>
        </div>
      </div>
      <p>As the Chief Technology Officer for ERATE<sup>&reg;</sup> I lead the complete revamp and modernization of the entire site and rebranded their design.  I was also the sole developer that created their CMS system from the ground up which allowed editors to edit articles and also import lender data to generate dynamic rate pages.  This lead to a significant increase in search engine result pages (SERP) in Google when searching for things like home equity loans.</p>
      <p className="skillsets-used">HTML/CSS/Javascript, jQuery, Bootstrap, React Native, Illustrator, Photoshop, PHP, ColdFusion, Lucee, MySQL, AWS, CloudFlare</p>
    </div>
  },
  {
    img: '/images/portfolio-cube/microsoft-cio-cloud-for-tomorrow-website2.jpg',
    description: 'A campaign website created for Microsoft to promote Azure Cloud Computing Services',
    title: 'Microsoft Azure Marketing Campaign',
    filter: 'microsoft marketing campaign',
    content: <div>
      <div className="visual-gallery">
        <div className="gallery-images">
          <img src="/images/portfolio/websites/cloud-for-tomorrow-campaign-website_sm.jpg" alt="Screenshot of Microsoft Cloud fo Tomorrow Campaign" />
        </div>
        <div className="gallery-description">
          <p>Developed marketing microsite for Microsoft Azure Cloud Services</p>
        </div>
      </div>
      <p>A team at Microsoft had hired us on to develop a marketing website for them to promote their Azure cloud services early on before it started getting big.  I worked closely with their designers and webops teams to launch this informational website using their services.</p>
      <p className="skillsets-used">HTML/CSS/Javascript, Photoshop, Azure, Wordpress, MySQL</p>
    </div>
  },
  {
    img: '/images/portfolio-cube/e3-insider-website.jpg',
    description: 'E3 Insider brought news and reveals to gamers who were not able to attend E3 in person.',
    title: 'E3 Insider',
    filter: 'e3 gaming event',
    content: <div>
      <div className="visual-gallery">
        <div className="gallery-images">
          <img src="/images/portfolio/websites/e3insider-website-development-2016_sm.jpg" alt="Screenshot of E3 Insider 2016" />
        </div>
        <div className="gallery-description">
          <p>Designed and developed several iterations of E3Insider.com for each year.</p>
        </div>
      </div>
      <p>E3 Insider was a website that launched alongside the actual E3 Expo show.  It provided videogame fans the ability to learn of upcoming videogames as they were being announced at the actual event.  We worked with companies like Twitch to integrate live streaming of press conferences from Microsoft, Nintendo, Sony, and others.</p>
      <p>Behind the scenes was a robust Content Management System that we had built that allowed exhibitors and editors to input videos and articles for the site.  The frontend website also had an interactive map using Firebase for realtime announcements.</p>
      <p className="skillsets-used">HTML/CSS/Javascript, jQuery, Firebase, Zurb Foundation Framework, Photoshop, AWS, Oracle DB, ColdFusion, CFWheels</p>
    </div>
  },
  {
    img: '/images/portfolio-cube/e3-expo-website.jpg',
    description: 'E3 was the largest gaming-expo of its time.',
    title: 'E3 Expo',
    filter: 'e3 marketing event show',
    content: <div>
      <div className="visual-gallery">
        <div className="gallery-images">
          <img src="/images/portfolio/websites/e3expo-website-development-2015_sm.jpg" alt="Screenshot of E3 Expo 2015" />
        </div>
        <div className="gallery-description">
          <p>Designed and developed several iterations of E3Expo.com for each year.</p>
        </div>
      </div>
      <p>E3 was one of the largest video game events that took place each year.  And boy was I super happy when I heard I was going to be working on their website (and finally step foot into the actual event).</p>
      <p>For this project, I maintained and updated the design of the website each year.  I had also worked on a two man team to enhance the CMS capabilities.</p>
      <p className="skillsets-used">HTML/CSS/Javascript, jQuery, Firebase, Zurb Foundation Framework, Photoshop, AWS, Oracle DB, ColdFusion, CFWheels</p>
    </div>
  },
  {
    img: '/images/portfolio-cube/techmanity-website.jpg',
    description: 'An event that celebrated the innovations that make our world a better place.',
    title: 'Techmanity ',
    filter: 'techmanity event show',
    content: <div>
      <div className="visual-gallery">
        <div className="gallery-images">
          <img src="/images/portfolio/websites/techmanity-website-development-2014_sm.jpg" alt="Screenshot of Techmanity 2014" />
        </div>
        <div className="gallery-description">
          <p>Developed custom Wordpress theme and plugin for Techmanity event</p>
        </div>
      </div>
      <p>Techmanity was one of those events that could have really taken off.  It celebrated the companies, entrepreneurs, inventors & investors who are revolutionizing our world.  It had speakers like Gavin Newsom, Scott Budman, Robert Patrick, Jared Leto, and ended with a Weezer concert.</p>
      <p>I was part of a two man team to develop the website and also create an in-house WordPress plugin so that editors can easily enter in data and dynamically generate speaker information and an agenda calendar.</p>
      <p className="skillsets-used">HTML/CSS/Javascript, jQuery, PHP, WordPress, Zurb Foundation Framework, Photoshop, AWS, MySQL</p>
    </div>
  },
  {
    img: '/images/portfolio-cube/gamepro-custom-solutions.jpg',
    description: 'An up-and-coming website agency that catered to tech and video gaming related events.',
    title: 'GamePro Custom Solutions',
    filter: 'gamepro gaming media website agency'
  },
  {
    img: '/images/portfolio-cube/game-marketing-summit-logo.jpg',
    description: 'An annual event for marketing professionals in the video gaming space.',
    title: 'Game Marketing Summit',
    filter: 'gamemarketingsummit marketing event awards'
  },
  {
    img: '/images/portfolio-cube/macworld-iworld.jpg',
    description: 'The world\'s ultimate fan event for those who use, create, and love Apple products.',
    title: 'Macworld / iWorld',
    filter: 'macworld iworld event show'
  },
  {
    img: '/images/portfolio-cube/gamepro-website.jpg',
    description: 'A popular video game magazine/media company that covered the video game industry.',
    title: 'GamePro Media',
    filter: 'gamepro marketing advertising',
    content: <div>
      <div className="visual-gallery">
        <div className="gallery-images">
          <img src="/images/portfolio/websites/gamepro-website-sm.jpg" alt="Screenshot of GamePro.com" />
        </div>
        <div className="gallery-description">
          <p>Assisted with redesign and enhancement of GamePro.com</p>
        </div>
      </div>
      <p>I actually had a subscription to their magazine as a kid and was a big fan.  When I was called and recruited by GamePro, it was like a dream come true!  A sad fact... I was the last official member of GamePro.</p>
      <p>I was signed on as a Senior Frontend Web Developer, assisting with making the site more user friendly and adding on new interactive modules.  During this time I was also brought on to work on the E3 Expo website when GamePro Media was partnered with the ESA.</p>
      <p className="skillsets-used">HTML/CSS/Javascript, ColdFusion, Oracle DB, GSAP, Photoshop</p>
    </div>
  },
  {
    img: '/images/portfolio-cube/hornblower-cruises-and-events-websites.jpg',
    description: 'Hornblower offered dining and sightseeing cruises as well as charters for special events or occasions.',
    title: 'Hornblower Cruises & Events',
    filter: 'hornblower event sightseeing cruise boat',
    content: <div>
      <p>I had worked with a talented team of marketers and developed several micro-sites that promoted special events or excursions, such as Alcatraz and Statue of Liberty cruises.  I had also assisted with HTML Email campaigns and interactive animations. In addition, our team was responsible for transitioning the main website content over to a content management system.</p>
      <p className="skillsets-used">HTML/CSS/Javascript, ASP.NET, Microsoft SQL Server, GSAP, Photoshop</p>
    </div>
  },

  // {
  //   img: '/images/portfolio-cube/homepage-design-mockup-collegecompetition-2016.jpg',
  //   title: 'E3 College Competition 2016',
  //   filter: 'e3 gaming competition college'
  // },
  // {
  //   img: '/images/portfolio-cube/ios-splash-game-marketing-summit-2015.png',
  //   title: '',
  //   filter: ''
  // },
  // {
  //   img: '/images/portfolio-cube/uploadpage-design-mockup-collegecompetition-2016.jpg',
  //   title: '',
  //   filter: 'e3 gaming competition college'
  // },

  
  // {
  //   img: '/images/portfolio-cube/cloudfortomorrow-website-development-2016.jpg',
  //   title: '',
  //   filter: 'microsoft'
  // },
  // {
  //   img: '/images/portfolio-cube/e3expo-website-development-2015.jpg',
  //   title: '',
  //   filter: 'e3 gaming event'
  // },
  // {
  //   img: '/images/portfolio-cube/e3insider-website-development-2016.jpg',
  //   title: '',
  //   filter: 'e3 gaming event'
  // },
  // {
  //   img: '/images/portfolio-cube/game-marketing-summit-website-development-2013.jpg',
  //   title: '',
  //   filter: 'gamemarketingsummit event awards show'
  // },
  // {
  //   img: '/images/portfolio-cube/game-marketing-summit-website-development-2014.jpg',
  //   title: '',
  //   filter: 'gamemarketingsummit event awards show'
  // },
  // {
  //   img: '/images/portfolio-cube/macworld-iworld-website-development-2013.jpg',
  //   title: '',
  //   filter: 'macworld event show'
  // },
  // {
  //   img: '/images/portfolio-cube/macworld-iworld-website-development-2014.jpg',
  //   title: '',
  //   filter: 'macworld event show'
  // },
  // {
  //   img: '/images/portfolio-cube/techmanity-speakers-website-development-2014.jpg',
  //   title: '',
  //   filter: 'techmanity event show'
  // },
  // {
  //   img: '/images/portfolio-cube/techmanity-website-development-2014.jpg',
  //   title: '',
  //   filter: 'techmanity event show'
  // }
];