import React, { useContext } from 'react';
import { AppContext } from "../state/State";

import {
  Link as RouterLink,
  NavLink

  
} from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import { AppLogo } from '../template/app-logo';
import useWindowDimensions from '../helpers/useWindowDimensions';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';

import Link from '@mui/material/Link';

import BurstModeIcon from '@mui/icons-material/BurstMode';
import DescriptionIcon from '@mui/icons-material/Description';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';

import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

import leftNavBg from '../static/images/left-nav-bg2.jpg';

import './menu.css';
import { useTheme } from '@mui/material/styles';

// import { Anchor } from '../helpers/drawer-helpers';

export const drawerWidth = 240;

const Menu = () => {
  const { state, dispatch } = useContext<any>(AppContext);
  const { prefersReducedMotion } = state;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { height, width } = useWindowDimensions();

  const theme = useTheme();

  const drawerWidthVal = width < 900 ? drawerWidth : 0;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  
  const togglePrefersReducedMotion = () => {
    dispatch({
      type: "togglePrefersReducedMotion",
      payload: {}
    });
  }

  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <div style={{margin: '20px auto'}}><AppLogo /></div>
      <Box sx={{px: 2, pb: 2}}>
        <Typography variant="body2" sx={{mb: 1}} align="center">My name is Christopher Lee and my passion is designing interactive UI/UX experiences.</Typography>
        <Box sx={{mb: 1, textAlign: 'center'}} className="nav-social-links">
          <Link href="https://www.linkedin.com/in/thatdevguy/" color="secondary" target="_blank" sx={{'&:hover': {color: 'red'}}}><LinkedInIcon /></Link>
          <Link href="mailto:chris@thatdevguy.com" color="secondary"><EmailIcon /></Link>
        </Box>
        <Divider />
      </Box>
      
      <List className="menu-links">
        <ListItem button component={NavLink} to={"/portfolio"} onClick={() => setMobileOpen(false)}>
          <ListItemIcon>
            <BurstModeIcon />
          </ListItemIcon>
          <ListItemText primary={"Portfolio"} />
        </ListItem>
        <ListItem button component={NavLink} to={"/resume"} onClick={() => setMobileOpen(false)}>
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary={"Resume"} />
        </ListItem>
        <ListItem button component={NavLink} to={"/about"} onClick={() => setMobileOpen(false)}>
          <ListItemIcon>
            <AccountBoxIcon />
          </ListItemIcon>
          <ListItemText primary={"About"} />
        </ListItem>
        <ListItem button component={NavLink} to={"/"} onClick={() => setMobileOpen(false)}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItem>
      </List>
      
      <div style={{textAlign: 'center', position: 'absolute', top: '500px', left: '50%', width: '100%', transform: 'translateX(-50%)'}}>
      <ToggleButton color="primary" value={prefersReducedMotion} aria-label={!prefersReducedMotion ? 'Enable Animations' : 'Reduce Animations'} onClick={togglePrefersReducedMotion} style={{margin: '0 auto', background: '#fff'}} size="small">
{prefersReducedMotion ? <ViewTimelineIcon sx={{mr: 1}} /> : <IndeterminateCheckBoxIcon sx={{mr: 1}} /> }
        {prefersReducedMotion ? 'Enable Animations' : 'Reduce Animations'}
      </ToggleButton>
      </div>
    </div>
  );

  return(<>
      {width < 900 && <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidthVal}px)` },
          ml: { sm: `${drawerWidthVal}px` },
        }}
      >
        <Toolbar sx={{background: theme.palette.secondary.main}}>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ color: '#fff', mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{color: '#fff', fontSize: '36px', lineHeight: 'normal', paddingTop: '4px'}}>
            ThatDevGuy
          </Typography>
        </Toolbar>
      </AppBar>}

      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="menu"
      >
        {width < 900 ?
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', md: 'none' },
              '& .MuiDrawer-paper': { 
                boxSizing: 'border-box', 
                width: drawerWidth,
                background: `url(${leftNavBg}) center top no-repeat`, 
                backgroundSize: '100% auto'
              },
            }}
          >
            {drawer}
          </Drawer>
          :
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box', 
                width: drawerWidth,
                background: `url(${leftNavBg}) center top no-repeat`, 
                backgroundSize: '100% auto'
              }
            }}
            open
          >
            {drawer}
          </Drawer>
        }
      </Box>
    
    </>
  )
}

export default Menu;